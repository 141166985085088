'use client';

import { chatState$ } from '@/features/chat/chat.state';
import { UserType } from '@/lib/global-types';

export const OPLogo = () => {
  const pathname = window.location.pathname;
  const cookieStore = window.localStorage;
  const userType = cookieStore.getItem('userType') as UserType | undefined;
  const chatIsOpen = chatState$.dialogOpen.get();

  if (
    !chatIsOpen &&
    (pathname.startsWith('/dashboard/admin') || userType === 'ADMIN')
  )
    return (
      <svg
        width="40"
        height="24"
        viewBox="0 0 40 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          x="0.761719"
          y="0.5625"
          width="38.475"
          height="22.875"
          rx="11.4375"
          fill="white"
        />
        <rect
          x="0.761719"
          y="0.5625"
          width="38.475"
          height="22.875"
          rx="11.4375"
          stroke="white"
          strokeWidth="1.125"
        />
        <g>
          <path
            d="M7.48 12.192C7.48 15.184 9.144 16.832 11.256 16.832C13.368 16.832 15.032 15.184 15.032 12.192C15.032 9.2 13.368 7.568 11.256 7.568C9.144 7.568 7.48 9.2 7.48 12.192ZM16.696 12.192C16.696 15.696 14.6 18.208 11.256 18.208C7.912 18.208 5.816 15.696 5.816 12.192C5.816 8.688 7.912 6.192 11.256 6.192C14.6 6.192 16.696 8.688 16.696 12.192ZM18.4116 6.4H22.0436C24.8756 6.4 26.8596 7.04 26.8596 9.904C26.8596 12.784 24.8756 13.408 22.0436 13.408H20.0436V18H18.4116V6.4ZM22.1716 7.776H20.0436V11.984H22.1716C23.8836 11.984 25.1956 11.808 25.1956 9.904C25.1956 8.016 23.8836 7.776 22.1716 7.776Z"
            fill="black"
          />
        </g>
        <g>
          <path
            d="M28.304 9.112C28.304 7.32 29.64 6.096 31.304 6.096C32.96 6.096 34.296 7.32 34.296 9.112C34.296 10.888 32.96 12.104 31.304 12.104C29.64 12.104 28.304 10.888 28.304 9.112ZM28.896 9.112C28.896 10.584 29.96 11.568 31.304 11.568C32.68 11.568 33.704 10.584 33.704 9.112C33.704 7.624 32.68 6.632 31.304 6.632C29.96 6.632 28.896 7.624 28.896 9.112ZM32.048 10.592L31.4 9.376H31.256H30.792V10.592H30.136V7.4H31.112C32.048 7.4 32.616 7.568 32.616 8.392C32.616 8.744 32.448 9.056 32.04 9.24L32.776 10.592H32.048ZM31.96 8.4C31.96 8.016 31.664 7.92 31.216 7.92H30.792V8.872H31.256C31.76 8.872 31.96 8.712 31.96 8.4Z"
            fill="black"
          />
        </g>
      </svg>
    );

  return (
    <svg
      width="40"
      height="24"
      viewBox="0 0 106 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2.19999"
        y="1.4"
        width="101.6"
        height="53.2"
        rx="26.6"
        stroke="currentColor"
        strokeWidth="3px"
      />
      <path
        d="M18.944 28.7376C18.944 37.1152 23.6032 41.7296 29.5168 41.7296C35.4304 41.7296 40.0896 37.1152 40.0896 28.7376C40.0896 20.36 35.4304 15.7904 29.5168 15.7904C23.6032 15.7904 18.944 20.36 18.944 28.7376ZM44.7488 28.7376C44.7488 38.5488 38.88 45.5824 29.5168 45.5824C20.1536 45.5824 14.2848 38.5488 14.2848 28.7376C14.2848 18.9264 20.1536 11.9376 29.5168 11.9376C38.88 11.9376 44.7488 18.9264 44.7488 28.7376ZM49.5525 12.52H59.7221C67.6517 12.52 73.2069 14.312 73.2069 22.3312C73.2069 30.3952 67.6517 32.1424 59.7221 32.1424H54.1221V45H49.5525V12.52ZM60.0805 16.3728H54.1221V28.1552H60.0805C64.8741 28.1552 68.5477 27.6624 68.5477 22.3312C68.5477 17.0448 64.8741 16.3728 60.0805 16.3728Z"
        fill="currentColor"
      />
      <path
        d="M75.8512 22.9136C75.8512 17.896 79.592 14.4688 84.2512 14.4688C88.888 14.4688 92.6288 17.896 92.6288 22.9136C92.6288 27.8864 88.888 31.2912 84.2512 31.2912C79.592 31.2912 75.8512 27.8864 75.8512 22.9136ZM77.5088 22.9136C77.5088 27.0352 80.488 29.7904 84.2512 29.7904C88.104 29.7904 90.9712 27.0352 90.9712 22.9136C90.9712 18.7472 88.104 15.9696 84.2512 15.9696C80.488 15.9696 77.5088 18.7472 77.5088 22.9136ZM86.3344 27.0576L84.52 23.6528H84.1168H82.8176V27.0576H80.9808V18.12H83.7136C86.3344 18.12 87.9248 18.5904 87.9248 20.8976C87.9248 21.8832 87.4544 22.7568 86.312 23.272L88.3728 27.0576H86.3344ZM86.088 20.92C86.088 19.8448 85.2592 19.576 84.0048 19.576H82.8176V22.2416H84.1168C85.528 22.2416 86.088 21.7936 86.088 20.92Z"
        fill="currentColor"
      />
    </svg>
  );
};

OPLogo.displayName = 'OPLogo';
