import { createFileRoute } from '@tanstack/react-router';

import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { founderNewState$ } from '@/store/founder.state';

export const Route = createFileRoute('/new/founder/company')({
  component: function Page() {
    const name = founderNewState$.name.get();
    const company_name = founderNewState$.company_name.get();
    const navigate = Route.useNavigate();

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      navigate({
        to: '/new/founder/outcome',
      });
    };

    return (
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center text-center">
        <h1>
          {`Good to meet you, ${name}`} <br />
          {'What’s the name of your startup'}
        </h1>

        <div className="mt-20">
          <Input
            autoFocus
            type={'text'}
            onChange={e => {
              founderNewState$.company_name.set(e.target.value);
            }}
            placeholder={'Company name'}
            value={company_name}
            size={1}
          />
        </div>

        <BottomNavigation
          left={<BackButton to="/new/founder/email" />}
          middle={company_name && <Button type="submit">Next</Button>}
        />
      </form>
    );
  },
});
