import {
  BookingType,
  EstimatedStartDate,
  FounderPlan,
  FounderStage,
  FounderStatus,
  OptionType,
  Prisma,
} from '@prisma/client';
import { z } from 'zod';

import { SERVICES } from './constants';

export const FounderStageSchema = z.enum([
  'UPSTART',
  'GROWTH',
  'LEGACY',
]) satisfies z.Schema<FounderStage>;

export const EstimatedStartDateSchema = z.enum([
  'NEXT_WEEK',
  'NEXT_MONTH',
  'LATER',
]) satisfies z.Schema<EstimatedStartDate>;

export const FounderPlanSchema = z.enum([
  'ESSENTIAL',
  'EXTENDED',
  'BEYOND',
]) satisfies z.Schema<FounderPlan>;

export const FounderStatusSchema = z.enum([
  'ONBOARDING',
  'ONBOARDED',

  'INTRO_CALL_ENDED',
  'INVOICE_SENT',
  'PAYMENT_MADE',
  'KICKOFF_CALL_ENDED',
  'OPTIONS_CONFIRMED',
  'PROJECT_STARTED',
  'REJECTED',

  'OPTIONS_CREATED',
  'OPTIONS_SENT',
  'INTRO_CALL_REQUEST_SENT',
  'INTRO_CALL_BOOKED',
  'INTRO_CALL_STARTED',
  'KICKOFF_CALL_REQUEST_SENT',
  'KICKOFF_CALL_BOOKED',
  'KICKOFF_CALL_STARTED',
]) satisfies z.Schema<FounderStatus>;

export const founderServiceSchema = z.enum(SERVICES);

export const OptionSchema = z.object({
  plan: FounderPlanSchema.optional().nullable(),
  services: z.array(founderServiceSchema).optional(),
  type: z.enum([
    'REQUESTED',
    'RECOMMENDED',
    'SUGGESTED',
  ]) satisfies z.Schema<OptionType>,
}) satisfies z.Schema<Prisma.OptionCreateWithoutOptionPackInput>;

export const OptionPackSchema = z.object({
  startDate: z.string(),
  description: z.string(),
  options: OptionSchema.array(),
});

export const founderCreateInputSchema = z.object({
  name: z.string(),
  company_name: z.string(),
  email: z.string().email(),
  project_description: z.string(),
  estimated_start_date: EstimatedStartDateSchema,
  requested_option: OptionSchema,
});

/**
 * @deprecated
 */
export const founderOnboardInputSchema = z.object({
  name: z.string(),
  company_name: z.string().nonempty(),
  time_zone: z.string().nonempty(),
  project_description: z.string(),
  links: z.array(z.string()).optional(),
  is_newsletter_allowed: z.boolean().default(false),
});

export const bookCallInputSchema = z.object({
  calcom_id: z.string(),
  date: z.string(),
  type: z.enum(['INTRO_CALL', 'KICKOFF_CALL']) satisfies z.Schema<BookingType>,
  duration: z.number(),
});

export const founderSelectOptionInputSchema = z.object({
  optionId: z.number(),
});

export const founderAddBillingDetailsInputSchema = z.object({
  legal_name: z.string(),
  country: z.string(),
  company_address: z.string(),
  vat_number: z.string().optional(),
});

export const founderAddReceiptInputSchema = z.object({
  receipt_url: z.string(),
});

export const founderUpdateSubscriptionInputSchema = z.object({
  id: z.number(),
});
