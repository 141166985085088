import { createFileRoute } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { useState } from 'react';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { CopyEmailButton } from '@/components/copy-email-button';
import { FoundersTag } from '@/components/founders-tag';
import { OfferButton } from '@/components/proposals/offer-button';
import { OfferDialog } from '@/components/proposals/offer-dialog';
import { SlideContainer } from '@/components/slide-container';
import { Spinner } from '@/components/spinner';
import { Button } from '@/components/ui/button';
import { SYSTEM_TIME } from '@/lib/constants';
import { Option } from '@/lib/global-types';
import { cn, formatTimezone, isBlankOption } from '@/lib/utils';

const today = SYSTEM_TIME;

const nextMonday =
  today().day() === 1
    ? today().add(7, 'day')
    : today().add(8 - today().day(), 'day'); // Otherwise, find the next Monday

const POSSIBLE_DATES = [
  nextMonday.add(1, 'week'),
  nextMonday.add(2, 'week'),
  nextMonday.add(3, 'week'),
];

export const Route = createFileRoute(
  '/dashboard/admin/founders/$founderId/proposal',
)({
  loader: async ({ params }) => {
    const founder = await trpcClientUtils.admin.founder.get.fetch(
      {
        id: parseInt(params.founderId),
      },
      {
        staleTime: 0,
        gcTime: 0,
      },
    );

    return { founder };
  },

  gcTime: 0,
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: function Page() {
    /*   const { founder, proposal: initialProposal } = Route.useLoaderData(); */
    const { founder } = Route.useLoaderData();
    const navigate = Route.useNavigate();

    const initialOption = founder.optionsPack;

    const requestedOption = initialOption.options.find(
      o => o.type === 'REQUESTED',
    )!;

    const [optionDescription, _setOptionDescription] = useState(
      initialOption.description || '',
    );

    const [selectedDate, setSelectedDate] = useState(
      dayjs(initialOption.startDate || nextMonday.toDate()),
    );
    const [isRequestedOfferDiloagOpen, setIsRequestedOfferDiloagOpen] =
      useState(false);
    const [isRecommendedOfferDialogOpen, setIsRecommendedOfferDialogOpen] =
      useState(false);
    const [isSuggestionOfferDialogOpen, setIsSuggestionOfferDialogOpen] =
      useState(false);

    const [state, setState] = useState(() => {
      const recommendedOption = initialOption.options.find(
        o => o.type === 'RECOMMENDED',
      );
      const suggestedOption = initialOption.options.find(
        o => o.type === 'SUGGESTED',
      );

      const createBlankOption = (type: Option['type']) => ({
        ...requestedOption,
        services: [],
        plan: null,
        type,
      });

      return {
        requested: requestedOption,
        recommendation: recommendedOption || createBlankOption('RECOMMENDED'),
        suggestion: suggestedOption || createBlankOption('SUGGESTED'),
      };
    });

    const onSave = async () => {
      const options = [state.requested, state.recommendation];

      if (!isBlankOption(state.recommendation)) {
        options.push(state.suggestion);
      }

      await saveMutation.mutateAsync({
        id: founder.id,
        optionPack: {
          description: optionDescription,
          startDate: selectedDate.toISOString(),
          options,
        },
      });

      navigate({
        to: '..',
      });
    };

    const saveMutation = trpc.admin.founder.saveOption.useMutation();
    return (
      <>
        <SlideContainer className="w-f flex w-full flex-col items-center pb-52 text-center">
          <FoundersTag
            className="mb-20"
            founder={founder}
            renderSection={['stage']}
          />
          <div className="mb-20 flex flex-col items-center">
            <CopyEmailButton text={founder.name!} email={founder.user.email} />
            <p className="underline">{founder.company_name}</p>
            {founder.time_zone && <p>{formatTimezone(founder.time_zone)}</p>}
            {/* // TOOD add timezone componenet */}
          </div>

          <div className="flex flex-col items-center gap-6 md:flex-row">
            <OfferButton
              title="Request"
              payload={state.requested}
              onClick={() => {
                setIsRequestedOfferDiloagOpen(true);
              }}
            />

            {/*    <OfferButton
              title="Recommendation"
              payload={state.recommendation}
              onClick={() => {
                setIsRecommendedOfferDialogOpen(true);
              }}
            />
            <OfferButton
              title="Suggestion"
              payload={state.suggestion}
              onClick={() => {
                setIsSuggestionOfferDialogOpen(true);
              }}
            /> */}
          </div>

          <OfferDialog
            open={isRequestedOfferDiloagOpen}
            onClose={() => {
              setIsRequestedOfferDiloagOpen(false);
              /* saveMutation.mutate(true); */
            }}
            option={state.requested}
            setOption={newOption => {
              if (newOption) setState({ ...state, requested: newOption });
            }}
          />

          <OfferDialog
            open={isRecommendedOfferDialogOpen}
            onClose={() => {
              setIsRecommendedOfferDialogOpen(false);
            }}
            option={state.recommendation}
            setOption={newOption => {
              if (newOption) {
                setState({ ...state, recommendation: newOption });
              }
            }}
          />

          <OfferDialog
            open={isSuggestionOfferDialogOpen}
            onClose={() => {
              setIsSuggestionOfferDialogOpen(false);
            }}
            option={state.suggestion}
            setOption={newOption => {
              if (newOption) {
                setState({ ...state, suggestion: newOption });
              }
            }}
          />

          {/*   <div className="mt-20 flex flex-col items-center gap-6">
            <h2>Note</h2>
            <Textarea
              placeholder={'Note'}
              value={optionDescription}
              onChange={e => {
                setOptionDescription(e.target.value);
              }}
            />
          </div>
 */}
          {/* Date */}
          <div className="mt-20 flex flex-col items-center gap-6">
            <h2>Starting Date</h2>
            <div className="flex gap-2">
              {POSSIBLE_DATES.map(date => (
                <Button
                  key={date.toString()}
                  className={cn(
                    date.isSame(selectedDate, 'day') && 'bg-primary text-white',
                  )}
                  onClick={() => {
                    setSelectedDate(date);
                  }}>
                  <span className="w-[70%]">{date.format('MMM D ddd')}</span>
                </Button>
              ))}
            </div>
          </div>
        </SlideContainer>
        <BottomNavigation
          left={<BackButton />}
          middle={
            <Button onClick={onSave} loading={saveMutation.isPending}>
              Send
            </Button>
          }
        />
      </>
    );
  },
});
