import { createFileRoute } from '@tanstack/react-router';

import { trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { founderNewState$ } from '@/store/founder.state';

export const Route = createFileRoute('/new/founder/')({
  loader: () => {
    // pre-load services and plans
    Promise.all([
      trpcClientUtils.misc.services.ensureData(),
      trpcClientUtils.misc.plans.ensureData(),
    ]);
  },
  component: function Page() {
    const name = founderNewState$.name.get();
    const navigate = Route.useNavigate();

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      navigate({
        to: '/new/founder/email',
      });
    };

    return (
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center text-center">
        <h1>
          {'Welcome to OP.'} <br />
          {'What should we call you?'}
        </h1>

        <div className="mt-20">
          <Input
            autoFocus
            type={'text'}
            onChange={e => {
              founderNewState$.name.set(e.target.value);
            }}
            placeholder={'Your name'}
            value={name}
            size={1}
          />
        </div>

        <BottomNavigation
          left={<BackButton native />}
          middle={name && <Button type="submit">Next</Button>}
        />
      </form>
    );
  },
});
