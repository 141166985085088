import { observable } from '@legendapp/state';

import { TRPCRouterInput } from '@/lib/global-types';

export type DesignerCreateInput = TRPCRouterInput['designer']['create'];

export type CreativesTiers = 'L1' | 'L2' | 'L3';

export type DesignerCreate = {
  services: DesignerCreateInput['services'];
  samples: DesignerCreateInput['samples'];
  email: DesignerCreateInput['email'];
  links: DesignerCreateInput['links'];
  preferedStartDate: DesignerCreateInput['preferedStartDate'] | null;
};

export const designerCreateState$ = observable<DesignerCreate>({
  email: '',
  samples: [],
  services: [],
  links: [],
  preferedStartDate: null,
});

export const isDesignerCreateCompleted$ = observable(() => {
  return true;
});
