import '../styles/globals.css';

import * as Sentry from '@sentry/react';
import {
  createRootRouteWithContext,
  Link,
  Outlet,
} from '@tanstack/react-router';
import { AxiosError } from 'axios';
import { motion } from 'motion/react';
import { useEffect, useMemo, useState } from 'react';
import { Toaster } from 'sonner';

import { BottomNavigation } from '@/components/bottom-navigation';
import { HelpDialog } from '@/components/dialogs/common/help';
import { OPLogo } from '@/components/op-logo';
import { OPLogoLarge } from '@/components/op-logo-large';
import { Button } from '@/components/ui/button';
import { DevTools } from '@/features/devtools/dev-tools';
import { IS_DEV } from '@/lib/constants';
import { queryClient } from '@/lib/query-client';
import { useUmamiTrackPages } from '@/lib/umami';

export const Route = createRootRouteWithContext<{
  queryClient: typeof queryClient;
}>()({
  component: Page,
  errorComponent: function ErrorComp({ error }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const isPrivatePath = location.pathname.includes('dashboard');

    useEffect(() => {
      if (error) {
        Sentry.captureException(error);
      }
    }, [error]);

    const errorDetail = useMemo(() => {
      if (error instanceof AxiosError) {
        let serverMessage = '';

        try {
          serverMessage = JSON.parse(error.response?.data?.message);
        } catch (_) {
          serverMessage = error.response?.data?.message;
        }

        return {
          message: error.message,
          status: error.response?.status,
          data: error.response?.data,
          serverMessage: serverMessage,
        };
      }

      return {
        message: error.message,
      };
    }, [error]);

    return (
      <div className="container grid h-screen w-full place-items-center bg-white">
        <header className="fixed left-0 right-0 top-6 z-20 flex justify-center text-white mix-blend-difference md:top-10">
          <Link to="/">{isPrivatePath ? <OPLogo /> : <OPLogoLarge />}</Link>
        </header>

        <div className="flex flex-col gap-8">
          <div className="flex flex-col">
            <motion.h5 layout layoutDependency={isExpanded} className="mx-auto">
              Ooops, something went wrong
            </motion.h5>
            <motion.button
              layout
              layoutDependency={isExpanded}
              className="mx-auto mt-2 underline"
              onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? 'Hide' : 'View'} Details
            </motion.button>

            {isExpanded && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className={`mt-4 flex flex-col gap-4`}>
                <pre className="max-h-96 max-w-[500px] overflow-auto rounded-lg border-2 border-dashed border-gray-300 p-4 text-start">
                  {error.message} <br />
                  {JSON.stringify(errorDetail, null, 2)}
                </pre>
              </motion.div>
            )}
          </div>

          <BottomNavigation
            middle={
              <Button
                className="mx-auto self-center"
                onClick={() => location.reload()}>
                Retry
              </Button>
            }
          />
        </div>
      </div>
    );
  },
});

function Page() {
  useUmamiTrackPages();

  const isPlatformPath =
    location.pathname.includes('/dashboard') ||
    location.pathname.includes('/login') ||
    location.pathname.includes('/onboarding') ||
    location.pathname.includes('/new');

  return (
    <main className="w-full bg-white font-suisse text-primary antialiased">
      <Outlet />

      <HelpDialog />
      <Toaster />
      {/*  <Screensaver /> */}
      {isPlatformPath && IS_DEV && <DevTools />}
    </main>
  );
}
