import { createFileRoute, Link, redirect } from '@tanstack/react-router';
import dayjs from 'dayjs';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BottomNavigation } from '@/components/bottom-navigation';
import { CoundownTimer } from '@/components/countdown-timer';
import { helpDialog$ } from '@/components/dialogs/common/help';
import { SlideContainer } from '@/components/slide-container';
import { Spinner } from '@/components/spinner';
import { Button } from '@/components/ui/button';
import { useDesignerStatusUpdate } from '@/hooks/use-websocket';
import { DAYS, IS_DEV } from '@/lib/constants';

export const Route = createFileRoute('/dashboard/designer/_designer/')({
  loader: async () => {
    const designer = await trpcClientUtils.designer.me.fetch(undefined, {
      staleTime: 0,
      gcTime: 0,
    });

    switch (designer.status) {
      case 'PROJECT_ASSIGNED':
        throw redirect({
          to: `/dashboard/project/$projectId`,
          params: {
            projectId: designer.project!.id.toString(),
          },
          replace: true,
        });
    }
  },
  gcTime: 0,
  pendingComponent: () => (
    <div className="grid h-[100dvh] w-[100dvw] place-items-center">
      <Spinner />
    </div>
  ),
  component: function Page() {
    const [designer, designerQuery] = trpc.designer.me.useSuspenseQuery();

    useDesignerStatusUpdate(designer.id, () => designerQuery.refetch());

    const renderActionButton = () => {
      switch (designer.status) {
        case 'ONBOARDED':
          return (
            <>
              <p>
                Thank you. <br />
                Give us a few days to <br /> review your profile.
              </p>
              <CoundownTimer
                className="mt-10 md:mt-20"
                startDate={dayjs(designer.user.updated_at)}
                finishDate={dayjs(designer.user.updated_at).add(24, 'hour')}
                timeZone={designer.time_zone!}>
                Reviewing..
              </CoundownTimer>
            </>
          );

        // TODO: should we show this?
        /*    case 'INTRO_CALL_REQUEST_SENT':
          return (
            <IntroCallRequest
              userType="DESIGNER"
              email={designer.user.email}
              name={designer.name!}
              onSuccess={() => navigate({ to: '.' })}
            />
          ); */

        case 'INTRO_CALL_BOOKED': {
          const introCall = designer.bookings.find(
            b => b.type === 'INTRO_CALL',
          );

          if (!introCall) return null;

          return (
            <CoundownTimer
              startDate={dayjs(introCall.created_at)}
              finishDate={dayjs(introCall.date)}
              timeZone={designer.time_zone!}>
              First sync on {DAYS[dayjs(introCall.date).day()]} <br />
              {dayjs(introCall.date).format('DD MMM HH:mm')}
            </CoundownTimer>
          );
        }

        case 'INTRO_CALL_STARTED': {
          const introCall = designer.bookings.find(
            b => b.type === 'INTRO_CALL',
          );

          if (!introCall) return null;

          return (
            <div className="flex flex-col items-center gap-10 md:gap-20">
              <p className="text-center">It’s time. See you there.</p>
              <Button size="lg" asChild>
                <a
                  href={`https://app.cal.com/video/${introCall.calcom_id}`}
                  target="_blank"
                  rel="noreferrer">
                  Join the call
                </a>
              </Button>
            </div>
          );
        }

        case 'INTRO_CALL_ENDED': {
          return (
            <Button size="lg" disabled>
              Waiting for project assignment
            </Button>
          );
        }

        case 'PROJECT_ASSIGNED': {
          const project = designer.project;

          if (!project) return null;

          return (
            <Button size="lg" asChild>
              <Link
                to="/dashboard/project/$projectId"
                params={{ projectId: project.id.toString() }}>
                Go to project
              </Link>
            </Button>
          );
        }
      }
    };

    return (
      <div className="flex flex-col items-center gap-10 text-center md:gap-20">
        <SlideContainer animationKey={designer.status}>
          {renderActionButton()}
        </SlideContainer>

        {IS_DEV && (
          <code className="fixed left-5 top-5 flex flex-col items-start text-xs opacity-50">
            <span>email: {designer.user.email}</span>
            <span>id: {designer.id}</span>
            <span>status: {designer.status}</span>
          </code>
        )}

        <BottomNavigation
          left={
            <Button size={'sm'} onClick={() => helpDialog$.set(true)}>
              ?
            </Button>
          }
        />
      </div>
    );
  },
});
