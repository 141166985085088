import { observable } from '@legendapp/state';

import { TRPCRouterInput } from '@/lib/global-types';

export type AcademyMemberCreateInput =
  TRPCRouterInput['academyMember']['create'];

export type AcademyMemberCreate = {
  services: AcademyMemberCreateInput['services'];
  samples: AcademyMemberCreateInput['samples'];
  email: AcademyMemberCreateInput['email'];
  links: AcademyMemberCreateInput['links'];
  name: AcademyMemberCreateInput['name'];
  timeZone: AcademyMemberCreateInput['timeZone'];
  availableForNewProjects:
    | AcademyMemberCreateInput['availableForNewProjects']
    | null;
  timeZoneLabel: string;
};

export const academyMemberCreateState$ = observable<AcademyMemberCreate>({
  email: '',
  samples: [],
  services: [],
  links: [],
  name: '',
  timeZone: '',
  timeZoneLabel: '',
  availableForNewProjects: null,
});

export const isAcademyMemberCreateCompleted$ = observable(() => {
  return true
});
