import { observable } from '@legendapp/state';

import { TRPCRouterInput, TRPCRouterOutput } from '@/lib/global-types';

export type MonthlyEmailPreference = 'yes' | 'no' | undefined;
type FounderCreateInput = TRPCRouterInput['founder']['create'];

export type FounderNewState = {
  name: string;
  email: string;
  company_name: string;
  project_outcome_description: string;
  requested_option: {
    plan: FounderCreateInput['requested_option']['plan'];
    services: TRPCRouterOutput['misc']['services'];
  };
  estimated_start_date: FounderCreateInput['estimated_start_date'] | null;

  showDuration: boolean;
  requireDurationServiceTypes: TRPCRouterOutput['misc']['services'][number]['type'][];
  showServiceHighlight: boolean;
};
export const founderNewState$ = observable<FounderNewState>({
  name: '',
  email: '',
  company_name: '',
  project_outcome_description: '',
  requested_option: {
    plan: undefined,
    services: [],
  },
  showDuration: false,
  requireDurationServiceTypes: [],
  showServiceHighlight: false,
  estimated_start_date: null,
});

export const isFounderNewCompleted$ = observable(() => {
  return true;
});

export type FounderOnboardingState = {
  links: string[];
  time_zone: string | null;
  profile_picture: null;
  is_newsletter_allowed: MonthlyEmailPreference;
  timezone_label: string;
};

export const founderOnboardingState$ = observable<FounderOnboardingState>({
  links: [],
  is_newsletter_allowed: undefined,
  profile_picture: null,
  time_zone: null,
  timezone_label: '',
});

export const isFounderOnboardingCompleted$ = observable(() => {
  return true;
});
