import {
  SERVICE_DESCRIPTIONS,
  SERVICE_PRICES,
} from '@op-platform/backend/founder';
import { type ClassValue, clsx } from 'clsx';
import dayjs from 'dayjs';
import timezoneplugin from 'dayjs/plugin/timezone';
import utcplugin from 'dayjs/plugin/utc';
import DOMPurify from 'isomorphic-dompurify';
import parsePhoneNumber from 'libphonenumber-js';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';

import { BACKEND_URL } from '@/lib/constants';
import { toast } from '@/lib/utils/toast';

import {
  EstimatedStartDate,
  Option,
  Plan,
  Service,
  ServiceSize,
  ServiceType,
  Update,
  UserType,
} from '../global-types';

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const validateEmail = (email: string | undefined) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const replaceAndSanitize = (
  string: string,
  searchValue: string | RegExp,
  replaceValue: string,
) => {
  return DOMPurify.sanitize(string.replaceAll(searchValue, replaceValue));
};

export const generateKickOffDateLabel = (date: EstimatedStartDate) => {
  switch (date) {
    case 'NEXT_WEEK':
      return 'Next week';
    case 'NEXT_MONTH':
      return 'Next month';
    case 'LATER':
      return 'Later';
    default:
      return 'Later';
  }
};

export const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const signFile = async (fileName: string, fileType: string) => {
  const res = await fetch(
    `${BACKEND_URL}/storage/signed-put-url?filename=${fileName}&fileType=${fileType}`,
  );

  const json = await res.json();

  if (!res.ok) throw new Error('An error has occured with the file sign!');

  return json;
};

export const putFile = async (writeUrl: string, file: File) => {
  const res = await fetch(writeUrl, {
    method: 'PUT',
    body: file,
  });

  if (!res.ok) throw new Error('An error has occured with the file upload!');
};

export const urlSchema = z
  .string()
  .refine(
    value => /^(https?):\/\/(?=.*\.[a-z]{2,})[^\s$.?#].[^\s]*$/i.test(value),
    {
      message: 'Please enter a valid URL',
    },
  );

export const formatTimezone = (input: string) => {
  return input.replaceAll('_', ' ').replaceAll('/', ' / ');
};

export const unFormatTimezone = (input: string) => {
  return input.trim().replaceAll(' / ', '/').replaceAll(' ', '_');
};

export const timeFormatter = (
  date?: string | number | dayjs.Dayjs | Date | null,
  timezone?: string,
) => {
  dayjs.extend(utcplugin);
  dayjs.extend(timezoneplugin);

  if (timezone) {
    return dayjs(date).tz(timezone);
  }

  return dayjs(date);
};

/**
 * Zod schema that validates a phone number using `libphonenumber-js`.
 * Attempts to parse the provided value with a default country of `TR`.
 *
 * If the phone number is valid, the schema transforms the phone number into
 * an international format (e.g. `+358401234567`).
 */
export const zPhoneNumber = z.string().transform((value, ctx) => {
  const phoneNumber = parsePhoneNumber(value, {
    defaultCountry: 'TR',
  });

  if (!phoneNumber?.isValid()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Invalid phone number',
    });
    return z.NEVER;
  }

  return phoneNumber.formatInternational();
});

export const sizeToMountCount = (size: ServiceSize) => {
  switch (size) {
    case 'S':
      return 1;
    case 'M':
      return 2;
    case 'L':
      return 3;
    default:
      return 0;
  }
};

export const mountToSize = (mount: number) => {
  switch (mount) {
    case 1:
      return 'S';
    case 2:
      return 'M';
    case 3:
      return 'L';
    default:
      return null;
  }
};

export const sizeToMonth = (size: ServiceSize) => {
  switch (size) {
    case 'S':
      return '1 month';
    case 'M':
      return '2 months';
    case 'L':
      return '3 months';
  }
};

export const capitalize = (input: string) => {
  return input.charAt(0).toUpperCase() + input.toLocaleLowerCase().slice(1);
};

export const ORDER_PLANS: Plan[] = ['ESSENTIAL', 'EXTENDED', 'BEYOND'];
export const SIZE_ORDER = ['S', 'M', 'L'] as ServiceSize[];

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const getRatingColor = (rating: Update['rating']) => {
  switch (rating) {
    case 'OFF_TRACK':
      return 'bg-red';
    case 'ADJUST':
      return 'bg-yellow';
    case 'ON_TRACK':
      return 'bg-green';
    default:
      return '';
  }
};

export const convertServicesForUI = (services: Service[]) => {
  return services.map(service => {
    const [type, size] = service.split('_') as [ServiceType, ServiceSize];
    return {
      type,
      size,
      full: service,
      price: SERVICE_PRICES[service],
      description: SERVICE_DESCRIPTIONS[service],
    };
  });
};

export const getUserTypeFromLocalStorage = () => {
  const cookieStore = window.localStorage;

  const userType = cookieStore.getItem('userType') as UserType | undefined;
  const email = cookieStore.getItem('email') as string | undefined;
  const accessToken = cookieStore.getItem('accessToken') as string | undefined;

  // for old users
  if (userType && !email) {
    localStorage.clear();
    toast('Your session has expired. Please sign in again.');

    window.location.href = '/';
    throw new Error('Session expired');
  }

  return {
    isAdmin: userType === 'ADMIN',
    isFounder: userType === 'FOUNDER',
    isDesigner: userType === 'DESIGNER',
    isAuthenticated: !!userType,
    type: userType,
    email,
    accessToken,
  };
};

export const isBlankOption = (option?: Option) => {
  if (!option) return true;
  return option.services.length === 0 && option.plan === null;
};
