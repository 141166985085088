import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';

import { trpc } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { OfferButton } from '@/components/proposals/offer-button';
import { OfferDialog } from '@/components/proposals/offer-dialog';
import { TimezoneSelector } from '@/components/timezone-selector';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Option, TRPCRouterInput } from '@/lib/global-types';

type CreatFounderInput = TRPCRouterInput['admin']['founder']['add'];

export const Route = createFileRoute('/dashboard/admin/create-founder')({
  component: RouteComponent,
});

function RouteComponent() {
  const [payload, setPayload] = useState<
    Omit<CreatFounderInput, 'deliverable'>
  >({
    company_name: '',
    email: '',
    name: '',
    estimated_start_date: 'LATER',
    time_zone: '',
    project_description: '',
    requested_option: {
      type: 'REQUESTED',
      plan: null,
      services: [],
    },
  });

  const [founderRequestOption, setFounderRequestOption] = useState<Option>({
    id: 99999999,
    optionPackId: 99999999,
    description: '',
    plan: null,
    services: [],
    type: 'REQUESTED',
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const shouldShowSubmitButton =
    payload.name &&
    payload.email &&
    payload.company_name &&
    payload.time_zone &&
    payload.estimated_start_date;

  const createFounderMutation = trpc.admin.founder.add.useMutation();

  const onCreate = async () => {
    if (!shouldShowSubmitButton) {
      return;
    }
    // TODO: implement custom founder creation

    /*   const founder = await createFounderMutation.mutateAsync({
      ...payload,
      requested_option: founderRequestOption,
    });

    toast('Founder created successfully');
    navigate({
      to: '/dashboard/admin/founders/$founderId',
      params: {
        founderId: String(founder.id),
      },
      replace: true,
    }); */
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onCreate();
      }}>
      <div className="flex flex-col items-center gap-10 pb-40">
        <h1>Create Founder</h1>
        <Input
          placeholder="Founder Name"
          value={payload.name}
          onChange={e => setPayload({ ...payload, name: e.target.value })}
        />

        <Input
          placeholder="Founder Email"
          type="email"
          value={payload.email}
          onChange={e => setPayload({ ...payload, email: e.target.value })}
        />

        <Input
          placeholder="Company Name"
          value={payload.company_name}
          onChange={e =>
            setPayload({ ...payload, company_name: e.target.value })
          }
        />

        {/*     <Textarea
            className="w-full self-center"
            placeholder="Description"
            value={payload.project_outcome_description}
            onChange={e =>
              setPayload({
                ...payload,
                project_outcome_description: e.target.value,
              })
            }
          /> */}

        {/*       <div>
            <p className="mb-2">Links</p>
            <Links
              currentLink={currentLink}
              setCurrentLink={setCurrentLink}
              linkList={payload.links}
              setLinkList={links =>
                setPayload({ ...payload, links: links as never[] })
              }
            />
          </div> */}

        <div className="h-[340px]">
          <TimezoneSelector
            placeholder="Founder Timezone"
            defaultValue={payload.time_zone}
            fetchOnMount
            onChange={value => {
              setPayload({ ...payload, time_zone: value });
            }}
          />
        </div>

        <OfferButton
          title="Founder Request"
          payload={founderRequestOption}
          onClick={() => setIsDialogOpen(true)}
        />

        <OfferDialog
          open={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
            /* saveMutation.mutate(true); */
          }}
          option={founderRequestOption}
          setOption={newOption => {
            if (newOption) {
              setFounderRequestOption(newOption);
            }
          }}
        />
      </div>

      <BottomNavigation
        left={<BackButton />}
        middle={
          shouldShowSubmitButton && (
            <Button
              type="submit"
              loading={createFounderMutation.isPending}
              disabled={createFounderMutation.isPending}>
              Submit
            </Button>
          )
        }
      />
    </form>
  );
}
