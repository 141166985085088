import { createFileRoute, Link } from '@tanstack/react-router';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BottomNavigation } from '@/components/bottom-navigation';
import { FadeScaleContainer } from '@/components/fade-scale-container';
import { IconBackArrow } from '@/components/icons/icon-back-arrow';
import { Button } from '@/components/ui/button';
import { chatState$ } from '@/features/chat/chat.state';

export type Filter = 'past' | 'present' | 'next';
export const Route = createFileRoute('/dashboard/admin/_admin/')({
  loader: async () => {
    const user = trpcClientUtils.user.get.fetch();

    return {
      user,
    };
  },
  validateSearch: (search: Record<string, unknown>) => {
    return {
      filter: search.filter as Filter | undefined,
    };
  },
  component: function Page() {
    const { filter } = Route.useSearch();
    const [user] = trpc.user.get.useSuspenseQuery();
    const adminChannelQuery = trpc.chat.adminChannelId.useQuery();
    const unreadCount = chatState$.unreadCount.get();

    const hasFilter = filter !== undefined;

    const renderPage = () => {
      // if there is no filter, render the next, past, present buttons
      if (!hasFilter) {
        return (
          <div className="flex flex-col items-center gap-2 pt-10">
            <div className="flex items-center gap-2">
              <FadeScaleContainer duration={0.2} delay={0.1}>
                <Button asChild>
                  <Link to="/dashboard/admin/site-reports">Overview</Link>
                </Button>
              </FadeScaleContainer>
              <FadeScaleContainer duration={0.3} delay={0.2}>
                <Button asChild>
                  <Link to="/dashboard/admin/site-reports">Academy</Link>
                </Button>
              </FadeScaleContainer>
            </div>
            <div className="flex items-center gap-4">
              <FadeScaleContainer duration={0.2} delay={0.1}>
                <Button asChild>
                  <Link from={Route.fullPath} search={{ filter: 'past' }}>
                    Past
                  </Link>
                </Button>
              </FadeScaleContainer>
              <FadeScaleContainer duration={0.3} delay={0.2}>
                <Button asChild>
                  <Link from={Route.fullPath} search={{ filter: 'present' }}>
                    Present
                  </Link>
                </Button>
              </FadeScaleContainer>
              <FadeScaleContainer duration={0.3} delay={0.3}>
                <Button asChild>
                  <Link from={Route.fullPath} search={{ filter: 'next' }}>
                    Next
                  </Link>
                </Button>
              </FadeScaleContainer>
            </div>
          </div>
        );
      }

      return (
        <div className="flex flex-row gap-2 pt-10">
          <FadeScaleContainer duration={0.2} delay={0.1}>
            {filter === 'present' ? (
              <Button asChild>
                <Link from={Route.fullPath} to={'/dashboard/project'}>
                  Projects
                </Link>
              </Button>
            ) : (
              <Button asChild>
                <Link
                  from={Route.fullPath}
                  to={'/dashboard/admin/founders'}
                  search={{ filter: filter }}>
                  Founders
                </Link>
              </Button>
            )}
          </FadeScaleContainer>
          <FadeScaleContainer duration={0.2} delay={0.2}>
            <Button asChild>
              <Link
                from={Route.fullPath}
                to={'/dashboard/admin/creatives'}
                search={{ filter: filter }}>
                Creatives
              </Link>
            </Button>
          </FadeScaleContainer>

          <FadeScaleContainer duration={0.2} delay={0.2}>
            <Button asChild>
              <Link
                from={Route.fullPath}
                to={'/dashboard/admin/academy-members'}
                search={{ filter: filter, style: undefined }}>
                Academy
              </Link>
            </Button>
          </FadeScaleContainer>
        </div>
      );
    };

    return (
      <section className="flex flex-col justify-center">
        <FadeScaleContainer className="mx-auto">
          <Link to="/dashboard/settings">
            <img
              className="rounded-full border border-[#00000014]"
              src={user.image || '/blank-profile.jpeg'}
              alt={`${user.name} avatar`}
              width={40}
              height={40}
            />
          </Link>
        </FadeScaleContainer>

        <div className="mx-auto">
          {filter && <h1 className="mt-20 text-center capitalize">{filter}</h1>}
          {renderPage()}
        </div>

        <BottomNavigation
          left={
            filter && (
              <Button size={'sm'} asChild>
                <Link to={`/dashboard/admin`} search={{ filter: undefined }}>
                  <IconBackArrow />
                </Link>
              </Button>
            )
          }
          middle={
            !filter && (
              <Button
                className="realative"
                onClick={() => {
                  const adminChannelId = adminChannelQuery.data;
                  if (!adminChannelId) return null;
                  chatState$.channelId.set(adminChannelId);
                  chatState$.dialogOpen.set(true);
                  chatState$.unreadCount.set(0);
                }}>
                Chat
                {unreadCount > 0 && (
                  <div className="absolute -right-1 -top-1 flex h-10 w-10 items-center justify-center rounded-full bg-black text-white">
                    {unreadCount}
                  </div>
                )}
              </Button>
            )
          }
        />
      </section>
    );
  },
});
