/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as StartImport } from './routes/start'
import { Route as OnboardingImport } from './routes/onboarding'
import { Route as NewImport } from './routes/new'
import { Route as LoginImport } from './routes/login'
import { Route as DashboardImport } from './routes/dashboard'
import { Route as IndexImport } from './routes/index'
import { Route as NewIndexImport } from './routes/new/index'
import { Route as PresentationFounderImport } from './routes/presentation.founder'
import { Route as PresentationCreativesImport } from './routes/presentation.creatives'
import { Route as NewFounderImport } from './routes/new/founder'
import { Route as NewDesignerImport } from './routes/new/designer'
import { Route as NewAcademyImport } from './routes/new/academy'
import { Route as DashboardSettingsImport } from './routes/dashboard/settings'
import { Route as DashboardProjectImport } from './routes/dashboard/project'
import { Route as DashboardFounderImport } from './routes/dashboard/founder'
import { Route as DashboardDesignerImport } from './routes/dashboard/designer'
import { Route as DashboardAdminImport } from './routes/dashboard/admin'
import { Route as ApiRedirectImport } from './routes/api/redirect'
import { Route as MiscAcademyImport } from './routes/_misc/academy'
import { Route as MiscAboutImport } from './routes/_misc/about'
import { Route as SamplesSamplesIndexImport } from './routes/samples/_samples.index'
import { Route as NewFounderIndexImport } from './routes/new/founder/index'
import { Route as NewDesignerIndexImport } from './routes/new/designer/index'
import { Route as NewAcademyIndexImport } from './routes/new/academy/index'
import { Route as DashboardProjectIndexImport } from './routes/dashboard/project/index'
import { Route as NewFounderTimeImport } from './routes/new/founder/time'
import { Route as NewFounderPlansImport } from './routes/new/founder/plans'
import { Route as NewFounderOutcomeImport } from './routes/new/founder/outcome'
import { Route as NewFounderEmailImport } from './routes/new/founder/email'
import { Route as NewFounderCompanyImport } from './routes/new/founder/company'
import { Route as NewFounderCheckEmailImport } from './routes/new/founder/check-email'
import { Route as NewDesignerTimeImport } from './routes/new/designer/time'
import { Route as NewDesignerSamplesImport } from './routes/new/designer/samples'
import { Route as NewDesignerLinksImport } from './routes/new/designer/links'
import { Route as NewDesignerEmailImport } from './routes/new/designer/email'
import { Route as NewDesignerCheckMailImport } from './routes/new/designer/check-mail'
import { Route as NewAcademyTimeZoneImport } from './routes/new/academy/time-zone'
import { Route as NewAcademyThankYouImport } from './routes/new/academy/thank-you'
import { Route as NewAcademyServicesImport } from './routes/new/academy/services'
import { Route as NewAcademySamplesImport } from './routes/new/academy/samples'
import { Route as NewAcademyLinksImport } from './routes/new/academy/links'
import { Route as NewAcademyEmailImport } from './routes/new/academy/email'
import { Route as NewAcademyAvailabilityImport } from './routes/new/academy/availability'
import { Route as DashboardFounderPaymentImport } from './routes/dashboard/founder/payment'
import { Route as DashboardDesignerSettingsImport } from './routes/dashboard/designer/settings'
import { Route as DashboardAdminSiteReportsImport } from './routes/dashboard/admin/site-reports'
import { Route as DashboardAdminCreativesImport } from './routes/dashboard/admin/creatives'
import { Route as DashboardAdminCreateProjectImport } from './routes/dashboard/admin/create-project'
import { Route as DashboardAdminCreateInvoiceImport } from './routes/dashboard/admin/create-invoice'
import { Route as DashboardAdminCreateFounderImport } from './routes/dashboard/admin/create-founder'
import { Route as DashboardAdminAcademyMembersImport } from './routes/dashboard/admin/academy-members'
import { Route as MiscAcademyStoryImport } from './routes/_misc/academy_.story'
import { Route as MiscAboutMissionImport } from './routes/_misc/about_.mission'
import { Route as MiscNewsletterUpdatesImport } from './routes/_misc/_newsletter.updates'
import { Route as MiscNewsletterConfirmedImport } from './routes/_misc/_newsletter.confirmed'
import { Route as OnboardingCreativesTypeIndexImport } from './routes/onboarding/creatives.$type/index'
import { Route as DashboardFounderFounderIndexImport } from './routes/dashboard/founder/_founder.index'
import { Route as DashboardDesignerDesignerIndexImport } from './routes/dashboard/designer/_designer.index'
import { Route as DashboardAdminAdminIndexImport } from './routes/dashboard/admin/_admin.index'
import { Route as OnboardingCreativesTypeTimeZoneImport } from './routes/onboarding/creatives.$type/time-zone'
import { Route as OnboardingCreativesTypeLevelsImport } from './routes/onboarding/creatives.$type/levels'
import { Route as DashboardProjectProjectIdUpdatesImport } from './routes/dashboard/project/$projectId/updates'
import { Route as DashboardProjectProjectIdReviewsImport } from './routes/dashboard/project/$projectId/reviews'
import { Route as DashboardProjectProjectIdInfoImport } from './routes/dashboard/project/$projectId/info'
import { Route as DashboardProjectProjectIdAssignImport } from './routes/dashboard/project/$projectId/assign'
import { Route as DashboardAdminFoundersFounderIdImport } from './routes/dashboard/admin/founders/$founderId'
import { Route as DashboardAdminDesignersDesignerIdImport } from './routes/dashboard/admin/designers.$designerId'
import { Route as DashboardProjectProjectIdProjectIdIndexImport } from './routes/dashboard/project/$projectId/_$projectId.index'
import { Route as DashboardAdminFoundersFoundersIndexImport } from './routes/dashboard/admin/founders/_founders.index'
import { Route as DashboardAdminDesignersDesignerIdIndexImport } from './routes/dashboard/admin/designers.$designerId/index'
import { Route as DashboardAdminFoundersFounderIdProposalImport } from './routes/dashboard/admin/founders/$founderId/proposal'
import { Route as DashboardAdminFoundersFounderIdInvoiceImport } from './routes/dashboard/admin/founders/$founderId/invoice'
import { Route as DashboardAdminFoundersFounderIdInfoImport } from './routes/dashboard/admin/founders/$founderId/info'
import { Route as DashboardAdminFoundersFounderIdEditImport } from './routes/dashboard/admin/founders/$founderId/edit'
import { Route as DashboardAdminDesignersDesignerIdRateImport } from './routes/dashboard/admin/designers.$designerId/rate'
import { Route as DashboardAdminDesignersDesignerIdInfoImport } from './routes/dashboard/admin/designers.$designerId/info'
import { Route as DashboardAdminDesignersDesignerIdAssignImport } from './routes/dashboard/admin/designers.$designerId/assign'
import { Route as DashboardAdminFoundersFounderIdFounderIdIndexImport } from './routes/dashboard/admin/founders/$founderId/_$founderId.index'
import { Route as DashboardProjectProjectIdUpdateUpdateNumberThanksImport } from './routes/dashboard/project/$projectId/update.$updateNumber/thanks'
import { Route as DashboardProjectProjectIdUpdateUpdateNumberRateImport } from './routes/dashboard/project/$projectId/update.$updateNumber/rate'
import { Route as DashboardProjectProjectIdUpdateUpdateNumberNextImport } from './routes/dashboard/project/$projectId/update.$updateNumber/next'
import { Route as DashboardProjectProjectIdUpdateUpdateNumberFeedbackImport } from './routes/dashboard/project/$projectId/update.$updateNumber/feedback'
import { Route as DashboardProjectProjectIdUpdateUpdateNumberCreateImport } from './routes/dashboard/project/$projectId/update.$updateNumber/create'
import { Route as DashboardProjectProjectIdReviewReviewNumberRateImport } from './routes/dashboard/project/$projectId/review.$reviewNumber/rate'
import { Route as DashboardProjectProjectIdReviewReviewNumberNextImport } from './routes/dashboard/project/$projectId/review.$reviewNumber/next'
import { Route as DashboardProjectProjectIdReviewReviewNumberFeedbackImport } from './routes/dashboard/project/$projectId/review.$reviewNumber/feedback'
import { Route as DashboardProjectProjectIdReviewReviewNumberCreateImport } from './routes/dashboard/project/$projectId/review.$reviewNumber/create'
import { Route as DashboardProjectProjectIdUpdateUpdateNumberUpdateNumberIndexImport } from './routes/dashboard/project/$projectId/update.$updateNumber/_$updateNumber.index'
import { Route as DashboardProjectProjectIdReviewReviewNumberReviewNumberIndexImport } from './routes/dashboard/project/$projectId/review.$reviewNumber/_$reviewNumber.index'

// Create/Update Routes

const StartRoute = StartImport.update({
  id: '/start',
  path: '/start',
  getParentRoute: () => rootRoute,
} as any)

const OnboardingRoute = OnboardingImport.update({
  id: '/onboarding',
  path: '/onboarding',
  getParentRoute: () => rootRoute,
} as any)

const NewRoute = NewImport.update({
  id: '/new',
  path: '/new',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const DashboardRoute = DashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const NewIndexRoute = NewIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => NewRoute,
} as any)

const PresentationFounderRoute = PresentationFounderImport.update({
  id: '/presentation/founder',
  path: '/presentation/founder',
  getParentRoute: () => rootRoute,
} as any)

const PresentationCreativesRoute = PresentationCreativesImport.update({
  id: '/presentation/creatives',
  path: '/presentation/creatives',
  getParentRoute: () => rootRoute,
} as any)

const NewFounderRoute = NewFounderImport.update({
  id: '/founder',
  path: '/founder',
  getParentRoute: () => NewRoute,
} as any)

const NewDesignerRoute = NewDesignerImport.update({
  id: '/designer',
  path: '/designer',
  getParentRoute: () => NewRoute,
} as any)

const NewAcademyRoute = NewAcademyImport.update({
  id: '/academy',
  path: '/academy',
  getParentRoute: () => NewRoute,
} as any)

const DashboardSettingsRoute = DashboardSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardProjectRoute = DashboardProjectImport.update({
  id: '/project',
  path: '/project',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardFounderRoute = DashboardFounderImport.update({
  id: '/founder',
  path: '/founder',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardDesignerRoute = DashboardDesignerImport.update({
  id: '/designer',
  path: '/designer',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardAdminRoute = DashboardAdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => DashboardRoute,
} as any)

const ApiRedirectRoute = ApiRedirectImport.update({
  id: '/api/redirect',
  path: '/api/redirect',
  getParentRoute: () => rootRoute,
} as any)

const MiscAcademyRoute = MiscAcademyImport.update({
  id: '/_misc/academy',
  path: '/academy',
  getParentRoute: () => rootRoute,
} as any)

const MiscAboutRoute = MiscAboutImport.update({
  id: '/_misc/about',
  path: '/about',
  getParentRoute: () => rootRoute,
} as any)

const SamplesSamplesIndexRoute = SamplesSamplesIndexImport.update({
  id: '/samples/_samples/',
  path: '/samples/',
  getParentRoute: () => rootRoute,
} as any)

const NewFounderIndexRoute = NewFounderIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => NewFounderRoute,
} as any)

const NewDesignerIndexRoute = NewDesignerIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => NewDesignerRoute,
} as any)

const NewAcademyIndexRoute = NewAcademyIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => NewAcademyRoute,
} as any)

const DashboardProjectIndexRoute = DashboardProjectIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardProjectRoute,
} as any)

const NewFounderTimeRoute = NewFounderTimeImport.update({
  id: '/time',
  path: '/time',
  getParentRoute: () => NewFounderRoute,
} as any)

const NewFounderPlansRoute = NewFounderPlansImport.update({
  id: '/plans',
  path: '/plans',
  getParentRoute: () => NewFounderRoute,
} as any)

const NewFounderOutcomeRoute = NewFounderOutcomeImport.update({
  id: '/outcome',
  path: '/outcome',
  getParentRoute: () => NewFounderRoute,
} as any)

const NewFounderEmailRoute = NewFounderEmailImport.update({
  id: '/email',
  path: '/email',
  getParentRoute: () => NewFounderRoute,
} as any)

const NewFounderCompanyRoute = NewFounderCompanyImport.update({
  id: '/company',
  path: '/company',
  getParentRoute: () => NewFounderRoute,
} as any)

const NewFounderCheckEmailRoute = NewFounderCheckEmailImport.update({
  id: '/check-email',
  path: '/check-email',
  getParentRoute: () => NewFounderRoute,
} as any)

const NewDesignerTimeRoute = NewDesignerTimeImport.update({
  id: '/time',
  path: '/time',
  getParentRoute: () => NewDesignerRoute,
} as any)

const NewDesignerSamplesRoute = NewDesignerSamplesImport.update({
  id: '/samples',
  path: '/samples',
  getParentRoute: () => NewDesignerRoute,
} as any)

const NewDesignerLinksRoute = NewDesignerLinksImport.update({
  id: '/links',
  path: '/links',
  getParentRoute: () => NewDesignerRoute,
} as any)

const NewDesignerEmailRoute = NewDesignerEmailImport.update({
  id: '/email',
  path: '/email',
  getParentRoute: () => NewDesignerRoute,
} as any)

const NewDesignerCheckMailRoute = NewDesignerCheckMailImport.update({
  id: '/check-mail',
  path: '/check-mail',
  getParentRoute: () => NewDesignerRoute,
} as any)

const NewAcademyTimeZoneRoute = NewAcademyTimeZoneImport.update({
  id: '/time-zone',
  path: '/time-zone',
  getParentRoute: () => NewAcademyRoute,
} as any)

const NewAcademyThankYouRoute = NewAcademyThankYouImport.update({
  id: '/thank-you',
  path: '/thank-you',
  getParentRoute: () => NewAcademyRoute,
} as any)

const NewAcademyServicesRoute = NewAcademyServicesImport.update({
  id: '/services',
  path: '/services',
  getParentRoute: () => NewAcademyRoute,
} as any)

const NewAcademySamplesRoute = NewAcademySamplesImport.update({
  id: '/samples',
  path: '/samples',
  getParentRoute: () => NewAcademyRoute,
} as any)

const NewAcademyLinksRoute = NewAcademyLinksImport.update({
  id: '/links',
  path: '/links',
  getParentRoute: () => NewAcademyRoute,
} as any)

const NewAcademyEmailRoute = NewAcademyEmailImport.update({
  id: '/email',
  path: '/email',
  getParentRoute: () => NewAcademyRoute,
} as any)

const NewAcademyAvailabilityRoute = NewAcademyAvailabilityImport.update({
  id: '/availability',
  path: '/availability',
  getParentRoute: () => NewAcademyRoute,
} as any)

const DashboardFounderPaymentRoute = DashboardFounderPaymentImport.update({
  id: '/payment',
  path: '/payment',
  getParentRoute: () => DashboardFounderRoute,
} as any)

const DashboardDesignerSettingsRoute = DashboardDesignerSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => DashboardDesignerRoute,
} as any)

const DashboardAdminSiteReportsRoute = DashboardAdminSiteReportsImport.update({
  id: '/site-reports',
  path: '/site-reports',
  getParentRoute: () => DashboardAdminRoute,
} as any)

const DashboardAdminCreativesRoute = DashboardAdminCreativesImport.update({
  id: '/creatives',
  path: '/creatives',
  getParentRoute: () => DashboardAdminRoute,
} as any)

const DashboardAdminCreateProjectRoute =
  DashboardAdminCreateProjectImport.update({
    id: '/create-project',
    path: '/create-project',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardAdminCreateInvoiceRoute =
  DashboardAdminCreateInvoiceImport.update({
    id: '/create-invoice',
    path: '/create-invoice',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardAdminCreateFounderRoute =
  DashboardAdminCreateFounderImport.update({
    id: '/create-founder',
    path: '/create-founder',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardAdminAcademyMembersRoute =
  DashboardAdminAcademyMembersImport.update({
    id: '/academy-members',
    path: '/academy-members',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const MiscAcademyStoryRoute = MiscAcademyStoryImport.update({
  id: '/_misc/academy_/story',
  path: '/academy/story',
  getParentRoute: () => rootRoute,
} as any)

const MiscAboutMissionRoute = MiscAboutMissionImport.update({
  id: '/_misc/about_/mission',
  path: '/about/mission',
  getParentRoute: () => rootRoute,
} as any)

const MiscNewsletterUpdatesRoute = MiscNewsletterUpdatesImport.update({
  id: '/_misc/_newsletter/updates',
  path: '/updates',
  getParentRoute: () => rootRoute,
} as any)

const MiscNewsletterConfirmedRoute = MiscNewsletterConfirmedImport.update({
  id: '/_misc/_newsletter/confirmed',
  path: '/confirmed',
  getParentRoute: () => rootRoute,
} as any)

const OnboardingCreativesTypeIndexRoute =
  OnboardingCreativesTypeIndexImport.update({
    id: '/creatives/$type/',
    path: '/creatives/$type/',
    getParentRoute: () => OnboardingRoute,
  } as any)

const DashboardFounderFounderIndexRoute =
  DashboardFounderFounderIndexImport.update({
    id: '/_founder/',
    path: '/',
    getParentRoute: () => DashboardFounderRoute,
  } as any)

const DashboardDesignerDesignerIndexRoute =
  DashboardDesignerDesignerIndexImport.update({
    id: '/_designer/',
    path: '/',
    getParentRoute: () => DashboardDesignerRoute,
  } as any)

const DashboardAdminAdminIndexRoute = DashboardAdminAdminIndexImport.update({
  id: '/_admin/',
  path: '/',
  getParentRoute: () => DashboardAdminRoute,
} as any)

const OnboardingCreativesTypeTimeZoneRoute =
  OnboardingCreativesTypeTimeZoneImport.update({
    id: '/creatives/$type/time-zone',
    path: '/creatives/$type/time-zone',
    getParentRoute: () => OnboardingRoute,
  } as any)

const OnboardingCreativesTypeLevelsRoute =
  OnboardingCreativesTypeLevelsImport.update({
    id: '/creatives/$type/levels',
    path: '/creatives/$type/levels',
    getParentRoute: () => OnboardingRoute,
  } as any)

const DashboardProjectProjectIdUpdatesRoute =
  DashboardProjectProjectIdUpdatesImport.update({
    id: '/$projectId/updates',
    path: '/$projectId/updates',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

const DashboardProjectProjectIdReviewsRoute =
  DashboardProjectProjectIdReviewsImport.update({
    id: '/$projectId/reviews',
    path: '/$projectId/reviews',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

const DashboardProjectProjectIdInfoRoute =
  DashboardProjectProjectIdInfoImport.update({
    id: '/$projectId/info',
    path: '/$projectId/info',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

const DashboardProjectProjectIdAssignRoute =
  DashboardProjectProjectIdAssignImport.update({
    id: '/$projectId/assign',
    path: '/$projectId/assign',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

const DashboardAdminFoundersFounderIdRoute =
  DashboardAdminFoundersFounderIdImport.update({
    id: '/founders/$founderId',
    path: '/founders/$founderId',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardAdminDesignersDesignerIdRoute =
  DashboardAdminDesignersDesignerIdImport.update({
    id: '/designers/$designerId',
    path: '/designers/$designerId',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardProjectProjectIdProjectIdIndexRoute =
  DashboardProjectProjectIdProjectIdIndexImport.update({
    id: '/$projectId/_$projectId/',
    path: '/$projectId/',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

const DashboardAdminFoundersFoundersIndexRoute =
  DashboardAdminFoundersFoundersIndexImport.update({
    id: '/founders/_founders/',
    path: '/founders/',
    getParentRoute: () => DashboardAdminRoute,
  } as any)

const DashboardAdminDesignersDesignerIdIndexRoute =
  DashboardAdminDesignersDesignerIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => DashboardAdminDesignersDesignerIdRoute,
  } as any)

const DashboardAdminFoundersFounderIdProposalRoute =
  DashboardAdminFoundersFounderIdProposalImport.update({
    id: '/proposal',
    path: '/proposal',
    getParentRoute: () => DashboardAdminFoundersFounderIdRoute,
  } as any)

const DashboardAdminFoundersFounderIdInvoiceRoute =
  DashboardAdminFoundersFounderIdInvoiceImport.update({
    id: '/invoice',
    path: '/invoice',
    getParentRoute: () => DashboardAdminFoundersFounderIdRoute,
  } as any)

const DashboardAdminFoundersFounderIdInfoRoute =
  DashboardAdminFoundersFounderIdInfoImport.update({
    id: '/info',
    path: '/info',
    getParentRoute: () => DashboardAdminFoundersFounderIdRoute,
  } as any)

const DashboardAdminFoundersFounderIdEditRoute =
  DashboardAdminFoundersFounderIdEditImport.update({
    id: '/edit',
    path: '/edit',
    getParentRoute: () => DashboardAdminFoundersFounderIdRoute,
  } as any)

const DashboardAdminDesignersDesignerIdRateRoute =
  DashboardAdminDesignersDesignerIdRateImport.update({
    id: '/rate',
    path: '/rate',
    getParentRoute: () => DashboardAdminDesignersDesignerIdRoute,
  } as any)

const DashboardAdminDesignersDesignerIdInfoRoute =
  DashboardAdminDesignersDesignerIdInfoImport.update({
    id: '/info',
    path: '/info',
    getParentRoute: () => DashboardAdminDesignersDesignerIdRoute,
  } as any)

const DashboardAdminDesignersDesignerIdAssignRoute =
  DashboardAdminDesignersDesignerIdAssignImport.update({
    id: '/assign',
    path: '/assign',
    getParentRoute: () => DashboardAdminDesignersDesignerIdRoute,
  } as any)

const DashboardAdminFoundersFounderIdFounderIdIndexRoute =
  DashboardAdminFoundersFounderIdFounderIdIndexImport.update({
    id: '/_$founderId/',
    path: '/',
    getParentRoute: () => DashboardAdminFoundersFounderIdRoute,
  } as any)

const DashboardProjectProjectIdUpdateUpdateNumberThanksRoute =
  DashboardProjectProjectIdUpdateUpdateNumberThanksImport.update({
    id: '/$projectId/update/$updateNumber/thanks',
    path: '/$projectId/update/$updateNumber/thanks',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

const DashboardProjectProjectIdUpdateUpdateNumberRateRoute =
  DashboardProjectProjectIdUpdateUpdateNumberRateImport.update({
    id: '/$projectId/update/$updateNumber/rate',
    path: '/$projectId/update/$updateNumber/rate',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

const DashboardProjectProjectIdUpdateUpdateNumberNextRoute =
  DashboardProjectProjectIdUpdateUpdateNumberNextImport.update({
    id: '/$projectId/update/$updateNumber/next',
    path: '/$projectId/update/$updateNumber/next',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

const DashboardProjectProjectIdUpdateUpdateNumberFeedbackRoute =
  DashboardProjectProjectIdUpdateUpdateNumberFeedbackImport.update({
    id: '/$projectId/update/$updateNumber/feedback',
    path: '/$projectId/update/$updateNumber/feedback',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

const DashboardProjectProjectIdUpdateUpdateNumberCreateRoute =
  DashboardProjectProjectIdUpdateUpdateNumberCreateImport.update({
    id: '/$projectId/update/$updateNumber/create',
    path: '/$projectId/update/$updateNumber/create',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

const DashboardProjectProjectIdReviewReviewNumberRateRoute =
  DashboardProjectProjectIdReviewReviewNumberRateImport.update({
    id: '/$projectId/review/$reviewNumber/rate',
    path: '/$projectId/review/$reviewNumber/rate',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

const DashboardProjectProjectIdReviewReviewNumberNextRoute =
  DashboardProjectProjectIdReviewReviewNumberNextImport.update({
    id: '/$projectId/review/$reviewNumber/next',
    path: '/$projectId/review/$reviewNumber/next',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

const DashboardProjectProjectIdReviewReviewNumberFeedbackRoute =
  DashboardProjectProjectIdReviewReviewNumberFeedbackImport.update({
    id: '/$projectId/review/$reviewNumber/feedback',
    path: '/$projectId/review/$reviewNumber/feedback',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

const DashboardProjectProjectIdReviewReviewNumberCreateRoute =
  DashboardProjectProjectIdReviewReviewNumberCreateImport.update({
    id: '/$projectId/review/$reviewNumber/create',
    path: '/$projectId/review/$reviewNumber/create',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

const DashboardProjectProjectIdUpdateUpdateNumberUpdateNumberIndexRoute =
  DashboardProjectProjectIdUpdateUpdateNumberUpdateNumberIndexImport.update({
    id: '/$projectId/update/$updateNumber/_$updateNumber/',
    path: '/$projectId/update/$updateNumber/',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

const DashboardProjectProjectIdReviewReviewNumberReviewNumberIndexRoute =
  DashboardProjectProjectIdReviewReviewNumberReviewNumberIndexImport.update({
    id: '/$projectId/review/$reviewNumber/_$reviewNumber/',
    path: '/$projectId/review/$reviewNumber/',
    getParentRoute: () => DashboardProjectRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/dashboard': {
      id: '/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/new': {
      id: '/new'
      path: '/new'
      fullPath: '/new'
      preLoaderRoute: typeof NewImport
      parentRoute: typeof rootRoute
    }
    '/onboarding': {
      id: '/onboarding'
      path: '/onboarding'
      fullPath: '/onboarding'
      preLoaderRoute: typeof OnboardingImport
      parentRoute: typeof rootRoute
    }
    '/start': {
      id: '/start'
      path: '/start'
      fullPath: '/start'
      preLoaderRoute: typeof StartImport
      parentRoute: typeof rootRoute
    }
    '/_misc/about': {
      id: '/_misc/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof MiscAboutImport
      parentRoute: typeof rootRoute
    }
    '/_misc/academy': {
      id: '/_misc/academy'
      path: '/academy'
      fullPath: '/academy'
      preLoaderRoute: typeof MiscAcademyImport
      parentRoute: typeof rootRoute
    }
    '/api/redirect': {
      id: '/api/redirect'
      path: '/api/redirect'
      fullPath: '/api/redirect'
      preLoaderRoute: typeof ApiRedirectImport
      parentRoute: typeof rootRoute
    }
    '/dashboard/admin': {
      id: '/dashboard/admin'
      path: '/admin'
      fullPath: '/dashboard/admin'
      preLoaderRoute: typeof DashboardAdminImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/designer': {
      id: '/dashboard/designer'
      path: '/designer'
      fullPath: '/dashboard/designer'
      preLoaderRoute: typeof DashboardDesignerImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/founder': {
      id: '/dashboard/founder'
      path: '/founder'
      fullPath: '/dashboard/founder'
      preLoaderRoute: typeof DashboardFounderImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/project': {
      id: '/dashboard/project'
      path: '/project'
      fullPath: '/dashboard/project'
      preLoaderRoute: typeof DashboardProjectImport
      parentRoute: typeof DashboardImport
    }
    '/dashboard/settings': {
      id: '/dashboard/settings'
      path: '/settings'
      fullPath: '/dashboard/settings'
      preLoaderRoute: typeof DashboardSettingsImport
      parentRoute: typeof DashboardImport
    }
    '/new/academy': {
      id: '/new/academy'
      path: '/academy'
      fullPath: '/new/academy'
      preLoaderRoute: typeof NewAcademyImport
      parentRoute: typeof NewImport
    }
    '/new/designer': {
      id: '/new/designer'
      path: '/designer'
      fullPath: '/new/designer'
      preLoaderRoute: typeof NewDesignerImport
      parentRoute: typeof NewImport
    }
    '/new/founder': {
      id: '/new/founder'
      path: '/founder'
      fullPath: '/new/founder'
      preLoaderRoute: typeof NewFounderImport
      parentRoute: typeof NewImport
    }
    '/presentation/creatives': {
      id: '/presentation/creatives'
      path: '/presentation/creatives'
      fullPath: '/presentation/creatives'
      preLoaderRoute: typeof PresentationCreativesImport
      parentRoute: typeof rootRoute
    }
    '/presentation/founder': {
      id: '/presentation/founder'
      path: '/presentation/founder'
      fullPath: '/presentation/founder'
      preLoaderRoute: typeof PresentationFounderImport
      parentRoute: typeof rootRoute
    }
    '/new/': {
      id: '/new/'
      path: '/'
      fullPath: '/new/'
      preLoaderRoute: typeof NewIndexImport
      parentRoute: typeof NewImport
    }
    '/_misc/_newsletter/confirmed': {
      id: '/_misc/_newsletter/confirmed'
      path: '/confirmed'
      fullPath: '/confirmed'
      preLoaderRoute: typeof MiscNewsletterConfirmedImport
      parentRoute: typeof rootRoute
    }
    '/_misc/_newsletter/updates': {
      id: '/_misc/_newsletter/updates'
      path: '/updates'
      fullPath: '/updates'
      preLoaderRoute: typeof MiscNewsletterUpdatesImport
      parentRoute: typeof rootRoute
    }
    '/_misc/about_/mission': {
      id: '/_misc/about_/mission'
      path: '/about/mission'
      fullPath: '/about/mission'
      preLoaderRoute: typeof MiscAboutMissionImport
      parentRoute: typeof rootRoute
    }
    '/_misc/academy_/story': {
      id: '/_misc/academy_/story'
      path: '/academy/story'
      fullPath: '/academy/story'
      preLoaderRoute: typeof MiscAcademyStoryImport
      parentRoute: typeof rootRoute
    }
    '/dashboard/admin/academy-members': {
      id: '/dashboard/admin/academy-members'
      path: '/academy-members'
      fullPath: '/dashboard/admin/academy-members'
      preLoaderRoute: typeof DashboardAdminAcademyMembersImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/create-founder': {
      id: '/dashboard/admin/create-founder'
      path: '/create-founder'
      fullPath: '/dashboard/admin/create-founder'
      preLoaderRoute: typeof DashboardAdminCreateFounderImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/create-invoice': {
      id: '/dashboard/admin/create-invoice'
      path: '/create-invoice'
      fullPath: '/dashboard/admin/create-invoice'
      preLoaderRoute: typeof DashboardAdminCreateInvoiceImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/create-project': {
      id: '/dashboard/admin/create-project'
      path: '/create-project'
      fullPath: '/dashboard/admin/create-project'
      preLoaderRoute: typeof DashboardAdminCreateProjectImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/creatives': {
      id: '/dashboard/admin/creatives'
      path: '/creatives'
      fullPath: '/dashboard/admin/creatives'
      preLoaderRoute: typeof DashboardAdminCreativesImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/site-reports': {
      id: '/dashboard/admin/site-reports'
      path: '/site-reports'
      fullPath: '/dashboard/admin/site-reports'
      preLoaderRoute: typeof DashboardAdminSiteReportsImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/designer/settings': {
      id: '/dashboard/designer/settings'
      path: '/settings'
      fullPath: '/dashboard/designer/settings'
      preLoaderRoute: typeof DashboardDesignerSettingsImport
      parentRoute: typeof DashboardDesignerImport
    }
    '/dashboard/founder/payment': {
      id: '/dashboard/founder/payment'
      path: '/payment'
      fullPath: '/dashboard/founder/payment'
      preLoaderRoute: typeof DashboardFounderPaymentImport
      parentRoute: typeof DashboardFounderImport
    }
    '/new/academy/availability': {
      id: '/new/academy/availability'
      path: '/availability'
      fullPath: '/new/academy/availability'
      preLoaderRoute: typeof NewAcademyAvailabilityImport
      parentRoute: typeof NewAcademyImport
    }
    '/new/academy/email': {
      id: '/new/academy/email'
      path: '/email'
      fullPath: '/new/academy/email'
      preLoaderRoute: typeof NewAcademyEmailImport
      parentRoute: typeof NewAcademyImport
    }
    '/new/academy/links': {
      id: '/new/academy/links'
      path: '/links'
      fullPath: '/new/academy/links'
      preLoaderRoute: typeof NewAcademyLinksImport
      parentRoute: typeof NewAcademyImport
    }
    '/new/academy/samples': {
      id: '/new/academy/samples'
      path: '/samples'
      fullPath: '/new/academy/samples'
      preLoaderRoute: typeof NewAcademySamplesImport
      parentRoute: typeof NewAcademyImport
    }
    '/new/academy/services': {
      id: '/new/academy/services'
      path: '/services'
      fullPath: '/new/academy/services'
      preLoaderRoute: typeof NewAcademyServicesImport
      parentRoute: typeof NewAcademyImport
    }
    '/new/academy/thank-you': {
      id: '/new/academy/thank-you'
      path: '/thank-you'
      fullPath: '/new/academy/thank-you'
      preLoaderRoute: typeof NewAcademyThankYouImport
      parentRoute: typeof NewAcademyImport
    }
    '/new/academy/time-zone': {
      id: '/new/academy/time-zone'
      path: '/time-zone'
      fullPath: '/new/academy/time-zone'
      preLoaderRoute: typeof NewAcademyTimeZoneImport
      parentRoute: typeof NewAcademyImport
    }
    '/new/designer/check-mail': {
      id: '/new/designer/check-mail'
      path: '/check-mail'
      fullPath: '/new/designer/check-mail'
      preLoaderRoute: typeof NewDesignerCheckMailImport
      parentRoute: typeof NewDesignerImport
    }
    '/new/designer/email': {
      id: '/new/designer/email'
      path: '/email'
      fullPath: '/new/designer/email'
      preLoaderRoute: typeof NewDesignerEmailImport
      parentRoute: typeof NewDesignerImport
    }
    '/new/designer/links': {
      id: '/new/designer/links'
      path: '/links'
      fullPath: '/new/designer/links'
      preLoaderRoute: typeof NewDesignerLinksImport
      parentRoute: typeof NewDesignerImport
    }
    '/new/designer/samples': {
      id: '/new/designer/samples'
      path: '/samples'
      fullPath: '/new/designer/samples'
      preLoaderRoute: typeof NewDesignerSamplesImport
      parentRoute: typeof NewDesignerImport
    }
    '/new/designer/time': {
      id: '/new/designer/time'
      path: '/time'
      fullPath: '/new/designer/time'
      preLoaderRoute: typeof NewDesignerTimeImport
      parentRoute: typeof NewDesignerImport
    }
    '/new/founder/check-email': {
      id: '/new/founder/check-email'
      path: '/check-email'
      fullPath: '/new/founder/check-email'
      preLoaderRoute: typeof NewFounderCheckEmailImport
      parentRoute: typeof NewFounderImport
    }
    '/new/founder/company': {
      id: '/new/founder/company'
      path: '/company'
      fullPath: '/new/founder/company'
      preLoaderRoute: typeof NewFounderCompanyImport
      parentRoute: typeof NewFounderImport
    }
    '/new/founder/email': {
      id: '/new/founder/email'
      path: '/email'
      fullPath: '/new/founder/email'
      preLoaderRoute: typeof NewFounderEmailImport
      parentRoute: typeof NewFounderImport
    }
    '/new/founder/outcome': {
      id: '/new/founder/outcome'
      path: '/outcome'
      fullPath: '/new/founder/outcome'
      preLoaderRoute: typeof NewFounderOutcomeImport
      parentRoute: typeof NewFounderImport
    }
    '/new/founder/plans': {
      id: '/new/founder/plans'
      path: '/plans'
      fullPath: '/new/founder/plans'
      preLoaderRoute: typeof NewFounderPlansImport
      parentRoute: typeof NewFounderImport
    }
    '/new/founder/time': {
      id: '/new/founder/time'
      path: '/time'
      fullPath: '/new/founder/time'
      preLoaderRoute: typeof NewFounderTimeImport
      parentRoute: typeof NewFounderImport
    }
    '/dashboard/project/': {
      id: '/dashboard/project/'
      path: '/'
      fullPath: '/dashboard/project/'
      preLoaderRoute: typeof DashboardProjectIndexImport
      parentRoute: typeof DashboardProjectImport
    }
    '/new/academy/': {
      id: '/new/academy/'
      path: '/'
      fullPath: '/new/academy/'
      preLoaderRoute: typeof NewAcademyIndexImport
      parentRoute: typeof NewAcademyImport
    }
    '/new/designer/': {
      id: '/new/designer/'
      path: '/'
      fullPath: '/new/designer/'
      preLoaderRoute: typeof NewDesignerIndexImport
      parentRoute: typeof NewDesignerImport
    }
    '/new/founder/': {
      id: '/new/founder/'
      path: '/'
      fullPath: '/new/founder/'
      preLoaderRoute: typeof NewFounderIndexImport
      parentRoute: typeof NewFounderImport
    }
    '/samples/_samples/': {
      id: '/samples/_samples/'
      path: '/samples'
      fullPath: '/samples'
      preLoaderRoute: typeof SamplesSamplesIndexImport
      parentRoute: typeof rootRoute
    }
    '/dashboard/admin/designers/$designerId': {
      id: '/dashboard/admin/designers/$designerId'
      path: '/designers/$designerId'
      fullPath: '/dashboard/admin/designers/$designerId'
      preLoaderRoute: typeof DashboardAdminDesignersDesignerIdImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/admin/founders/$founderId': {
      id: '/dashboard/admin/founders/$founderId'
      path: '/founders/$founderId'
      fullPath: '/dashboard/admin/founders/$founderId'
      preLoaderRoute: typeof DashboardAdminFoundersFounderIdImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/project/$projectId/assign': {
      id: '/dashboard/project/$projectId/assign'
      path: '/$projectId/assign'
      fullPath: '/dashboard/project/$projectId/assign'
      preLoaderRoute: typeof DashboardProjectProjectIdAssignImport
      parentRoute: typeof DashboardProjectImport
    }
    '/dashboard/project/$projectId/info': {
      id: '/dashboard/project/$projectId/info'
      path: '/$projectId/info'
      fullPath: '/dashboard/project/$projectId/info'
      preLoaderRoute: typeof DashboardProjectProjectIdInfoImport
      parentRoute: typeof DashboardProjectImport
    }
    '/dashboard/project/$projectId/reviews': {
      id: '/dashboard/project/$projectId/reviews'
      path: '/$projectId/reviews'
      fullPath: '/dashboard/project/$projectId/reviews'
      preLoaderRoute: typeof DashboardProjectProjectIdReviewsImport
      parentRoute: typeof DashboardProjectImport
    }
    '/dashboard/project/$projectId/updates': {
      id: '/dashboard/project/$projectId/updates'
      path: '/$projectId/updates'
      fullPath: '/dashboard/project/$projectId/updates'
      preLoaderRoute: typeof DashboardProjectProjectIdUpdatesImport
      parentRoute: typeof DashboardProjectImport
    }
    '/onboarding/creatives/$type/levels': {
      id: '/onboarding/creatives/$type/levels'
      path: '/creatives/$type/levels'
      fullPath: '/onboarding/creatives/$type/levels'
      preLoaderRoute: typeof OnboardingCreativesTypeLevelsImport
      parentRoute: typeof OnboardingImport
    }
    '/onboarding/creatives/$type/time-zone': {
      id: '/onboarding/creatives/$type/time-zone'
      path: '/creatives/$type/time-zone'
      fullPath: '/onboarding/creatives/$type/time-zone'
      preLoaderRoute: typeof OnboardingCreativesTypeTimeZoneImport
      parentRoute: typeof OnboardingImport
    }
    '/dashboard/admin/_admin/': {
      id: '/dashboard/admin/_admin/'
      path: '/'
      fullPath: '/dashboard/admin/'
      preLoaderRoute: typeof DashboardAdminAdminIndexImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/designer/_designer/': {
      id: '/dashboard/designer/_designer/'
      path: '/'
      fullPath: '/dashboard/designer/'
      preLoaderRoute: typeof DashboardDesignerDesignerIndexImport
      parentRoute: typeof DashboardDesignerImport
    }
    '/dashboard/founder/_founder/': {
      id: '/dashboard/founder/_founder/'
      path: '/'
      fullPath: '/dashboard/founder/'
      preLoaderRoute: typeof DashboardFounderFounderIndexImport
      parentRoute: typeof DashboardFounderImport
    }
    '/onboarding/creatives/$type/': {
      id: '/onboarding/creatives/$type/'
      path: '/creatives/$type'
      fullPath: '/onboarding/creatives/$type'
      preLoaderRoute: typeof OnboardingCreativesTypeIndexImport
      parentRoute: typeof OnboardingImport
    }
    '/dashboard/admin/designers/$designerId/assign': {
      id: '/dashboard/admin/designers/$designerId/assign'
      path: '/assign'
      fullPath: '/dashboard/admin/designers/$designerId/assign'
      preLoaderRoute: typeof DashboardAdminDesignersDesignerIdAssignImport
      parentRoute: typeof DashboardAdminDesignersDesignerIdImport
    }
    '/dashboard/admin/designers/$designerId/info': {
      id: '/dashboard/admin/designers/$designerId/info'
      path: '/info'
      fullPath: '/dashboard/admin/designers/$designerId/info'
      preLoaderRoute: typeof DashboardAdminDesignersDesignerIdInfoImport
      parentRoute: typeof DashboardAdminDesignersDesignerIdImport
    }
    '/dashboard/admin/designers/$designerId/rate': {
      id: '/dashboard/admin/designers/$designerId/rate'
      path: '/rate'
      fullPath: '/dashboard/admin/designers/$designerId/rate'
      preLoaderRoute: typeof DashboardAdminDesignersDesignerIdRateImport
      parentRoute: typeof DashboardAdminDesignersDesignerIdImport
    }
    '/dashboard/admin/founders/$founderId/edit': {
      id: '/dashboard/admin/founders/$founderId/edit'
      path: '/edit'
      fullPath: '/dashboard/admin/founders/$founderId/edit'
      preLoaderRoute: typeof DashboardAdminFoundersFounderIdEditImport
      parentRoute: typeof DashboardAdminFoundersFounderIdImport
    }
    '/dashboard/admin/founders/$founderId/info': {
      id: '/dashboard/admin/founders/$founderId/info'
      path: '/info'
      fullPath: '/dashboard/admin/founders/$founderId/info'
      preLoaderRoute: typeof DashboardAdminFoundersFounderIdInfoImport
      parentRoute: typeof DashboardAdminFoundersFounderIdImport
    }
    '/dashboard/admin/founders/$founderId/invoice': {
      id: '/dashboard/admin/founders/$founderId/invoice'
      path: '/invoice'
      fullPath: '/dashboard/admin/founders/$founderId/invoice'
      preLoaderRoute: typeof DashboardAdminFoundersFounderIdInvoiceImport
      parentRoute: typeof DashboardAdminFoundersFounderIdImport
    }
    '/dashboard/admin/founders/$founderId/proposal': {
      id: '/dashboard/admin/founders/$founderId/proposal'
      path: '/proposal'
      fullPath: '/dashboard/admin/founders/$founderId/proposal'
      preLoaderRoute: typeof DashboardAdminFoundersFounderIdProposalImport
      parentRoute: typeof DashboardAdminFoundersFounderIdImport
    }
    '/dashboard/admin/designers/$designerId/': {
      id: '/dashboard/admin/designers/$designerId/'
      path: '/'
      fullPath: '/dashboard/admin/designers/$designerId/'
      preLoaderRoute: typeof DashboardAdminDesignersDesignerIdIndexImport
      parentRoute: typeof DashboardAdminDesignersDesignerIdImport
    }
    '/dashboard/admin/founders/_founders/': {
      id: '/dashboard/admin/founders/_founders/'
      path: '/founders'
      fullPath: '/dashboard/admin/founders'
      preLoaderRoute: typeof DashboardAdminFoundersFoundersIndexImport
      parentRoute: typeof DashboardAdminImport
    }
    '/dashboard/project/$projectId/_$projectId/': {
      id: '/dashboard/project/$projectId/_$projectId/'
      path: '/$projectId'
      fullPath: '/dashboard/project/$projectId'
      preLoaderRoute: typeof DashboardProjectProjectIdProjectIdIndexImport
      parentRoute: typeof DashboardProjectImport
    }
    '/dashboard/project/$projectId/review/$reviewNumber/create': {
      id: '/dashboard/project/$projectId/review/$reviewNumber/create'
      path: '/$projectId/review/$reviewNumber/create'
      fullPath: '/dashboard/project/$projectId/review/$reviewNumber/create'
      preLoaderRoute: typeof DashboardProjectProjectIdReviewReviewNumberCreateImport
      parentRoute: typeof DashboardProjectImport
    }
    '/dashboard/project/$projectId/review/$reviewNumber/feedback': {
      id: '/dashboard/project/$projectId/review/$reviewNumber/feedback'
      path: '/$projectId/review/$reviewNumber/feedback'
      fullPath: '/dashboard/project/$projectId/review/$reviewNumber/feedback'
      preLoaderRoute: typeof DashboardProjectProjectIdReviewReviewNumberFeedbackImport
      parentRoute: typeof DashboardProjectImport
    }
    '/dashboard/project/$projectId/review/$reviewNumber/next': {
      id: '/dashboard/project/$projectId/review/$reviewNumber/next'
      path: '/$projectId/review/$reviewNumber/next'
      fullPath: '/dashboard/project/$projectId/review/$reviewNumber/next'
      preLoaderRoute: typeof DashboardProjectProjectIdReviewReviewNumberNextImport
      parentRoute: typeof DashboardProjectImport
    }
    '/dashboard/project/$projectId/review/$reviewNumber/rate': {
      id: '/dashboard/project/$projectId/review/$reviewNumber/rate'
      path: '/$projectId/review/$reviewNumber/rate'
      fullPath: '/dashboard/project/$projectId/review/$reviewNumber/rate'
      preLoaderRoute: typeof DashboardProjectProjectIdReviewReviewNumberRateImport
      parentRoute: typeof DashboardProjectImport
    }
    '/dashboard/project/$projectId/update/$updateNumber/create': {
      id: '/dashboard/project/$projectId/update/$updateNumber/create'
      path: '/$projectId/update/$updateNumber/create'
      fullPath: '/dashboard/project/$projectId/update/$updateNumber/create'
      preLoaderRoute: typeof DashboardProjectProjectIdUpdateUpdateNumberCreateImport
      parentRoute: typeof DashboardProjectImport
    }
    '/dashboard/project/$projectId/update/$updateNumber/feedback': {
      id: '/dashboard/project/$projectId/update/$updateNumber/feedback'
      path: '/$projectId/update/$updateNumber/feedback'
      fullPath: '/dashboard/project/$projectId/update/$updateNumber/feedback'
      preLoaderRoute: typeof DashboardProjectProjectIdUpdateUpdateNumberFeedbackImport
      parentRoute: typeof DashboardProjectImport
    }
    '/dashboard/project/$projectId/update/$updateNumber/next': {
      id: '/dashboard/project/$projectId/update/$updateNumber/next'
      path: '/$projectId/update/$updateNumber/next'
      fullPath: '/dashboard/project/$projectId/update/$updateNumber/next'
      preLoaderRoute: typeof DashboardProjectProjectIdUpdateUpdateNumberNextImport
      parentRoute: typeof DashboardProjectImport
    }
    '/dashboard/project/$projectId/update/$updateNumber/rate': {
      id: '/dashboard/project/$projectId/update/$updateNumber/rate'
      path: '/$projectId/update/$updateNumber/rate'
      fullPath: '/dashboard/project/$projectId/update/$updateNumber/rate'
      preLoaderRoute: typeof DashboardProjectProjectIdUpdateUpdateNumberRateImport
      parentRoute: typeof DashboardProjectImport
    }
    '/dashboard/project/$projectId/update/$updateNumber/thanks': {
      id: '/dashboard/project/$projectId/update/$updateNumber/thanks'
      path: '/$projectId/update/$updateNumber/thanks'
      fullPath: '/dashboard/project/$projectId/update/$updateNumber/thanks'
      preLoaderRoute: typeof DashboardProjectProjectIdUpdateUpdateNumberThanksImport
      parentRoute: typeof DashboardProjectImport
    }
    '/dashboard/admin/founders/$founderId/_$founderId/': {
      id: '/dashboard/admin/founders/$founderId/_$founderId/'
      path: '/'
      fullPath: '/dashboard/admin/founders/$founderId/'
      preLoaderRoute: typeof DashboardAdminFoundersFounderIdFounderIdIndexImport
      parentRoute: typeof DashboardAdminFoundersFounderIdImport
    }
    '/dashboard/project/$projectId/review/$reviewNumber/_$reviewNumber/': {
      id: '/dashboard/project/$projectId/review/$reviewNumber/_$reviewNumber/'
      path: '/$projectId/review/$reviewNumber'
      fullPath: '/dashboard/project/$projectId/review/$reviewNumber'
      preLoaderRoute: typeof DashboardProjectProjectIdReviewReviewNumberReviewNumberIndexImport
      parentRoute: typeof DashboardProjectImport
    }
    '/dashboard/project/$projectId/update/$updateNumber/_$updateNumber/': {
      id: '/dashboard/project/$projectId/update/$updateNumber/_$updateNumber/'
      path: '/$projectId/update/$updateNumber'
      fullPath: '/dashboard/project/$projectId/update/$updateNumber'
      preLoaderRoute: typeof DashboardProjectProjectIdUpdateUpdateNumberUpdateNumberIndexImport
      parentRoute: typeof DashboardProjectImport
    }
  }
}

// Create and export the route tree

interface DashboardAdminDesignersDesignerIdRouteChildren {
  DashboardAdminDesignersDesignerIdAssignRoute: typeof DashboardAdminDesignersDesignerIdAssignRoute
  DashboardAdminDesignersDesignerIdInfoRoute: typeof DashboardAdminDesignersDesignerIdInfoRoute
  DashboardAdminDesignersDesignerIdRateRoute: typeof DashboardAdminDesignersDesignerIdRateRoute
  DashboardAdminDesignersDesignerIdIndexRoute: typeof DashboardAdminDesignersDesignerIdIndexRoute
}

const DashboardAdminDesignersDesignerIdRouteChildren: DashboardAdminDesignersDesignerIdRouteChildren =
  {
    DashboardAdminDesignersDesignerIdAssignRoute:
      DashboardAdminDesignersDesignerIdAssignRoute,
    DashboardAdminDesignersDesignerIdInfoRoute:
      DashboardAdminDesignersDesignerIdInfoRoute,
    DashboardAdminDesignersDesignerIdRateRoute:
      DashboardAdminDesignersDesignerIdRateRoute,
    DashboardAdminDesignersDesignerIdIndexRoute:
      DashboardAdminDesignersDesignerIdIndexRoute,
  }

const DashboardAdminDesignersDesignerIdRouteWithChildren =
  DashboardAdminDesignersDesignerIdRoute._addFileChildren(
    DashboardAdminDesignersDesignerIdRouteChildren,
  )

interface DashboardAdminFoundersFounderIdRouteChildren {
  DashboardAdminFoundersFounderIdEditRoute: typeof DashboardAdminFoundersFounderIdEditRoute
  DashboardAdminFoundersFounderIdInfoRoute: typeof DashboardAdminFoundersFounderIdInfoRoute
  DashboardAdminFoundersFounderIdInvoiceRoute: typeof DashboardAdminFoundersFounderIdInvoiceRoute
  DashboardAdminFoundersFounderIdProposalRoute: typeof DashboardAdminFoundersFounderIdProposalRoute
  DashboardAdminFoundersFounderIdFounderIdIndexRoute: typeof DashboardAdminFoundersFounderIdFounderIdIndexRoute
}

const DashboardAdminFoundersFounderIdRouteChildren: DashboardAdminFoundersFounderIdRouteChildren =
  {
    DashboardAdminFoundersFounderIdEditRoute:
      DashboardAdminFoundersFounderIdEditRoute,
    DashboardAdminFoundersFounderIdInfoRoute:
      DashboardAdminFoundersFounderIdInfoRoute,
    DashboardAdminFoundersFounderIdInvoiceRoute:
      DashboardAdminFoundersFounderIdInvoiceRoute,
    DashboardAdminFoundersFounderIdProposalRoute:
      DashboardAdminFoundersFounderIdProposalRoute,
    DashboardAdminFoundersFounderIdFounderIdIndexRoute:
      DashboardAdminFoundersFounderIdFounderIdIndexRoute,
  }

const DashboardAdminFoundersFounderIdRouteWithChildren =
  DashboardAdminFoundersFounderIdRoute._addFileChildren(
    DashboardAdminFoundersFounderIdRouteChildren,
  )

interface DashboardAdminRouteChildren {
  DashboardAdminAcademyMembersRoute: typeof DashboardAdminAcademyMembersRoute
  DashboardAdminCreateFounderRoute: typeof DashboardAdminCreateFounderRoute
  DashboardAdminCreateInvoiceRoute: typeof DashboardAdminCreateInvoiceRoute
  DashboardAdminCreateProjectRoute: typeof DashboardAdminCreateProjectRoute
  DashboardAdminCreativesRoute: typeof DashboardAdminCreativesRoute
  DashboardAdminSiteReportsRoute: typeof DashboardAdminSiteReportsRoute
  DashboardAdminDesignersDesignerIdRoute: typeof DashboardAdminDesignersDesignerIdRouteWithChildren
  DashboardAdminFoundersFounderIdRoute: typeof DashboardAdminFoundersFounderIdRouteWithChildren
  DashboardAdminAdminIndexRoute: typeof DashboardAdminAdminIndexRoute
  DashboardAdminFoundersFoundersIndexRoute: typeof DashboardAdminFoundersFoundersIndexRoute
}

const DashboardAdminRouteChildren: DashboardAdminRouteChildren = {
  DashboardAdminAcademyMembersRoute: DashboardAdminAcademyMembersRoute,
  DashboardAdminCreateFounderRoute: DashboardAdminCreateFounderRoute,
  DashboardAdminCreateInvoiceRoute: DashboardAdminCreateInvoiceRoute,
  DashboardAdminCreateProjectRoute: DashboardAdminCreateProjectRoute,
  DashboardAdminCreativesRoute: DashboardAdminCreativesRoute,
  DashboardAdminSiteReportsRoute: DashboardAdminSiteReportsRoute,
  DashboardAdminDesignersDesignerIdRoute:
    DashboardAdminDesignersDesignerIdRouteWithChildren,
  DashboardAdminFoundersFounderIdRoute:
    DashboardAdminFoundersFounderIdRouteWithChildren,
  DashboardAdminAdminIndexRoute: DashboardAdminAdminIndexRoute,
  DashboardAdminFoundersFoundersIndexRoute:
    DashboardAdminFoundersFoundersIndexRoute,
}

const DashboardAdminRouteWithChildren = DashboardAdminRoute._addFileChildren(
  DashboardAdminRouteChildren,
)

interface DashboardDesignerRouteChildren {
  DashboardDesignerSettingsRoute: typeof DashboardDesignerSettingsRoute
  DashboardDesignerDesignerIndexRoute: typeof DashboardDesignerDesignerIndexRoute
}

const DashboardDesignerRouteChildren: DashboardDesignerRouteChildren = {
  DashboardDesignerSettingsRoute: DashboardDesignerSettingsRoute,
  DashboardDesignerDesignerIndexRoute: DashboardDesignerDesignerIndexRoute,
}

const DashboardDesignerRouteWithChildren =
  DashboardDesignerRoute._addFileChildren(DashboardDesignerRouteChildren)

interface DashboardFounderRouteChildren {
  DashboardFounderPaymentRoute: typeof DashboardFounderPaymentRoute
  DashboardFounderFounderIndexRoute: typeof DashboardFounderFounderIndexRoute
}

const DashboardFounderRouteChildren: DashboardFounderRouteChildren = {
  DashboardFounderPaymentRoute: DashboardFounderPaymentRoute,
  DashboardFounderFounderIndexRoute: DashboardFounderFounderIndexRoute,
}

const DashboardFounderRouteWithChildren =
  DashboardFounderRoute._addFileChildren(DashboardFounderRouteChildren)

interface DashboardProjectRouteChildren {
  DashboardProjectIndexRoute: typeof DashboardProjectIndexRoute
  DashboardProjectProjectIdAssignRoute: typeof DashboardProjectProjectIdAssignRoute
  DashboardProjectProjectIdInfoRoute: typeof DashboardProjectProjectIdInfoRoute
  DashboardProjectProjectIdReviewsRoute: typeof DashboardProjectProjectIdReviewsRoute
  DashboardProjectProjectIdUpdatesRoute: typeof DashboardProjectProjectIdUpdatesRoute
  DashboardProjectProjectIdProjectIdIndexRoute: typeof DashboardProjectProjectIdProjectIdIndexRoute
  DashboardProjectProjectIdReviewReviewNumberCreateRoute: typeof DashboardProjectProjectIdReviewReviewNumberCreateRoute
  DashboardProjectProjectIdReviewReviewNumberFeedbackRoute: typeof DashboardProjectProjectIdReviewReviewNumberFeedbackRoute
  DashboardProjectProjectIdReviewReviewNumberNextRoute: typeof DashboardProjectProjectIdReviewReviewNumberNextRoute
  DashboardProjectProjectIdReviewReviewNumberRateRoute: typeof DashboardProjectProjectIdReviewReviewNumberRateRoute
  DashboardProjectProjectIdUpdateUpdateNumberCreateRoute: typeof DashboardProjectProjectIdUpdateUpdateNumberCreateRoute
  DashboardProjectProjectIdUpdateUpdateNumberFeedbackRoute: typeof DashboardProjectProjectIdUpdateUpdateNumberFeedbackRoute
  DashboardProjectProjectIdUpdateUpdateNumberNextRoute: typeof DashboardProjectProjectIdUpdateUpdateNumberNextRoute
  DashboardProjectProjectIdUpdateUpdateNumberRateRoute: typeof DashboardProjectProjectIdUpdateUpdateNumberRateRoute
  DashboardProjectProjectIdUpdateUpdateNumberThanksRoute: typeof DashboardProjectProjectIdUpdateUpdateNumberThanksRoute
  DashboardProjectProjectIdReviewReviewNumberReviewNumberIndexRoute: typeof DashboardProjectProjectIdReviewReviewNumberReviewNumberIndexRoute
  DashboardProjectProjectIdUpdateUpdateNumberUpdateNumberIndexRoute: typeof DashboardProjectProjectIdUpdateUpdateNumberUpdateNumberIndexRoute
}

const DashboardProjectRouteChildren: DashboardProjectRouteChildren = {
  DashboardProjectIndexRoute: DashboardProjectIndexRoute,
  DashboardProjectProjectIdAssignRoute: DashboardProjectProjectIdAssignRoute,
  DashboardProjectProjectIdInfoRoute: DashboardProjectProjectIdInfoRoute,
  DashboardProjectProjectIdReviewsRoute: DashboardProjectProjectIdReviewsRoute,
  DashboardProjectProjectIdUpdatesRoute: DashboardProjectProjectIdUpdatesRoute,
  DashboardProjectProjectIdProjectIdIndexRoute:
    DashboardProjectProjectIdProjectIdIndexRoute,
  DashboardProjectProjectIdReviewReviewNumberCreateRoute:
    DashboardProjectProjectIdReviewReviewNumberCreateRoute,
  DashboardProjectProjectIdReviewReviewNumberFeedbackRoute:
    DashboardProjectProjectIdReviewReviewNumberFeedbackRoute,
  DashboardProjectProjectIdReviewReviewNumberNextRoute:
    DashboardProjectProjectIdReviewReviewNumberNextRoute,
  DashboardProjectProjectIdReviewReviewNumberRateRoute:
    DashboardProjectProjectIdReviewReviewNumberRateRoute,
  DashboardProjectProjectIdUpdateUpdateNumberCreateRoute:
    DashboardProjectProjectIdUpdateUpdateNumberCreateRoute,
  DashboardProjectProjectIdUpdateUpdateNumberFeedbackRoute:
    DashboardProjectProjectIdUpdateUpdateNumberFeedbackRoute,
  DashboardProjectProjectIdUpdateUpdateNumberNextRoute:
    DashboardProjectProjectIdUpdateUpdateNumberNextRoute,
  DashboardProjectProjectIdUpdateUpdateNumberRateRoute:
    DashboardProjectProjectIdUpdateUpdateNumberRateRoute,
  DashboardProjectProjectIdUpdateUpdateNumberThanksRoute:
    DashboardProjectProjectIdUpdateUpdateNumberThanksRoute,
  DashboardProjectProjectIdReviewReviewNumberReviewNumberIndexRoute:
    DashboardProjectProjectIdReviewReviewNumberReviewNumberIndexRoute,
  DashboardProjectProjectIdUpdateUpdateNumberUpdateNumberIndexRoute:
    DashboardProjectProjectIdUpdateUpdateNumberUpdateNumberIndexRoute,
}

const DashboardProjectRouteWithChildren =
  DashboardProjectRoute._addFileChildren(DashboardProjectRouteChildren)

interface DashboardRouteChildren {
  DashboardAdminRoute: typeof DashboardAdminRouteWithChildren
  DashboardDesignerRoute: typeof DashboardDesignerRouteWithChildren
  DashboardFounderRoute: typeof DashboardFounderRouteWithChildren
  DashboardProjectRoute: typeof DashboardProjectRouteWithChildren
  DashboardSettingsRoute: typeof DashboardSettingsRoute
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardAdminRoute: DashboardAdminRouteWithChildren,
  DashboardDesignerRoute: DashboardDesignerRouteWithChildren,
  DashboardFounderRoute: DashboardFounderRouteWithChildren,
  DashboardProjectRoute: DashboardProjectRouteWithChildren,
  DashboardSettingsRoute: DashboardSettingsRoute,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

interface NewAcademyRouteChildren {
  NewAcademyAvailabilityRoute: typeof NewAcademyAvailabilityRoute
  NewAcademyEmailRoute: typeof NewAcademyEmailRoute
  NewAcademyLinksRoute: typeof NewAcademyLinksRoute
  NewAcademySamplesRoute: typeof NewAcademySamplesRoute
  NewAcademyServicesRoute: typeof NewAcademyServicesRoute
  NewAcademyThankYouRoute: typeof NewAcademyThankYouRoute
  NewAcademyTimeZoneRoute: typeof NewAcademyTimeZoneRoute
  NewAcademyIndexRoute: typeof NewAcademyIndexRoute
}

const NewAcademyRouteChildren: NewAcademyRouteChildren = {
  NewAcademyAvailabilityRoute: NewAcademyAvailabilityRoute,
  NewAcademyEmailRoute: NewAcademyEmailRoute,
  NewAcademyLinksRoute: NewAcademyLinksRoute,
  NewAcademySamplesRoute: NewAcademySamplesRoute,
  NewAcademyServicesRoute: NewAcademyServicesRoute,
  NewAcademyThankYouRoute: NewAcademyThankYouRoute,
  NewAcademyTimeZoneRoute: NewAcademyTimeZoneRoute,
  NewAcademyIndexRoute: NewAcademyIndexRoute,
}

const NewAcademyRouteWithChildren = NewAcademyRoute._addFileChildren(
  NewAcademyRouteChildren,
)

interface NewDesignerRouteChildren {
  NewDesignerCheckMailRoute: typeof NewDesignerCheckMailRoute
  NewDesignerEmailRoute: typeof NewDesignerEmailRoute
  NewDesignerLinksRoute: typeof NewDesignerLinksRoute
  NewDesignerSamplesRoute: typeof NewDesignerSamplesRoute
  NewDesignerTimeRoute: typeof NewDesignerTimeRoute
  NewDesignerIndexRoute: typeof NewDesignerIndexRoute
}

const NewDesignerRouteChildren: NewDesignerRouteChildren = {
  NewDesignerCheckMailRoute: NewDesignerCheckMailRoute,
  NewDesignerEmailRoute: NewDesignerEmailRoute,
  NewDesignerLinksRoute: NewDesignerLinksRoute,
  NewDesignerSamplesRoute: NewDesignerSamplesRoute,
  NewDesignerTimeRoute: NewDesignerTimeRoute,
  NewDesignerIndexRoute: NewDesignerIndexRoute,
}

const NewDesignerRouteWithChildren = NewDesignerRoute._addFileChildren(
  NewDesignerRouteChildren,
)

interface NewFounderRouteChildren {
  NewFounderCheckEmailRoute: typeof NewFounderCheckEmailRoute
  NewFounderCompanyRoute: typeof NewFounderCompanyRoute
  NewFounderEmailRoute: typeof NewFounderEmailRoute
  NewFounderOutcomeRoute: typeof NewFounderOutcomeRoute
  NewFounderPlansRoute: typeof NewFounderPlansRoute
  NewFounderTimeRoute: typeof NewFounderTimeRoute
  NewFounderIndexRoute: typeof NewFounderIndexRoute
}

const NewFounderRouteChildren: NewFounderRouteChildren = {
  NewFounderCheckEmailRoute: NewFounderCheckEmailRoute,
  NewFounderCompanyRoute: NewFounderCompanyRoute,
  NewFounderEmailRoute: NewFounderEmailRoute,
  NewFounderOutcomeRoute: NewFounderOutcomeRoute,
  NewFounderPlansRoute: NewFounderPlansRoute,
  NewFounderTimeRoute: NewFounderTimeRoute,
  NewFounderIndexRoute: NewFounderIndexRoute,
}

const NewFounderRouteWithChildren = NewFounderRoute._addFileChildren(
  NewFounderRouteChildren,
)

interface NewRouteChildren {
  NewAcademyRoute: typeof NewAcademyRouteWithChildren
  NewDesignerRoute: typeof NewDesignerRouteWithChildren
  NewFounderRoute: typeof NewFounderRouteWithChildren
  NewIndexRoute: typeof NewIndexRoute
}

const NewRouteChildren: NewRouteChildren = {
  NewAcademyRoute: NewAcademyRouteWithChildren,
  NewDesignerRoute: NewDesignerRouteWithChildren,
  NewFounderRoute: NewFounderRouteWithChildren,
  NewIndexRoute: NewIndexRoute,
}

const NewRouteWithChildren = NewRoute._addFileChildren(NewRouteChildren)

interface OnboardingRouteChildren {
  OnboardingCreativesTypeLevelsRoute: typeof OnboardingCreativesTypeLevelsRoute
  OnboardingCreativesTypeTimeZoneRoute: typeof OnboardingCreativesTypeTimeZoneRoute
  OnboardingCreativesTypeIndexRoute: typeof OnboardingCreativesTypeIndexRoute
}

const OnboardingRouteChildren: OnboardingRouteChildren = {
  OnboardingCreativesTypeLevelsRoute: OnboardingCreativesTypeLevelsRoute,
  OnboardingCreativesTypeTimeZoneRoute: OnboardingCreativesTypeTimeZoneRoute,
  OnboardingCreativesTypeIndexRoute: OnboardingCreativesTypeIndexRoute,
}

const OnboardingRouteWithChildren = OnboardingRoute._addFileChildren(
  OnboardingRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/login': typeof LoginRoute
  '/new': typeof NewRouteWithChildren
  '/onboarding': typeof OnboardingRouteWithChildren
  '/start': typeof StartRoute
  '/about': typeof MiscAboutRoute
  '/academy': typeof MiscAcademyRoute
  '/api/redirect': typeof ApiRedirectRoute
  '/dashboard/admin': typeof DashboardAdminRouteWithChildren
  '/dashboard/designer': typeof DashboardDesignerRouteWithChildren
  '/dashboard/founder': typeof DashboardFounderRouteWithChildren
  '/dashboard/project': typeof DashboardProjectRouteWithChildren
  '/dashboard/settings': typeof DashboardSettingsRoute
  '/new/academy': typeof NewAcademyRouteWithChildren
  '/new/designer': typeof NewDesignerRouteWithChildren
  '/new/founder': typeof NewFounderRouteWithChildren
  '/presentation/creatives': typeof PresentationCreativesRoute
  '/presentation/founder': typeof PresentationFounderRoute
  '/new/': typeof NewIndexRoute
  '/confirmed': typeof MiscNewsletterConfirmedRoute
  '/updates': typeof MiscNewsletterUpdatesRoute
  '/about/mission': typeof MiscAboutMissionRoute
  '/academy/story': typeof MiscAcademyStoryRoute
  '/dashboard/admin/academy-members': typeof DashboardAdminAcademyMembersRoute
  '/dashboard/admin/create-founder': typeof DashboardAdminCreateFounderRoute
  '/dashboard/admin/create-invoice': typeof DashboardAdminCreateInvoiceRoute
  '/dashboard/admin/create-project': typeof DashboardAdminCreateProjectRoute
  '/dashboard/admin/creatives': typeof DashboardAdminCreativesRoute
  '/dashboard/admin/site-reports': typeof DashboardAdminSiteReportsRoute
  '/dashboard/designer/settings': typeof DashboardDesignerSettingsRoute
  '/dashboard/founder/payment': typeof DashboardFounderPaymentRoute
  '/new/academy/availability': typeof NewAcademyAvailabilityRoute
  '/new/academy/email': typeof NewAcademyEmailRoute
  '/new/academy/links': typeof NewAcademyLinksRoute
  '/new/academy/samples': typeof NewAcademySamplesRoute
  '/new/academy/services': typeof NewAcademyServicesRoute
  '/new/academy/thank-you': typeof NewAcademyThankYouRoute
  '/new/academy/time-zone': typeof NewAcademyTimeZoneRoute
  '/new/designer/check-mail': typeof NewDesignerCheckMailRoute
  '/new/designer/email': typeof NewDesignerEmailRoute
  '/new/designer/links': typeof NewDesignerLinksRoute
  '/new/designer/samples': typeof NewDesignerSamplesRoute
  '/new/designer/time': typeof NewDesignerTimeRoute
  '/new/founder/check-email': typeof NewFounderCheckEmailRoute
  '/new/founder/company': typeof NewFounderCompanyRoute
  '/new/founder/email': typeof NewFounderEmailRoute
  '/new/founder/outcome': typeof NewFounderOutcomeRoute
  '/new/founder/plans': typeof NewFounderPlansRoute
  '/new/founder/time': typeof NewFounderTimeRoute
  '/dashboard/project/': typeof DashboardProjectIndexRoute
  '/new/academy/': typeof NewAcademyIndexRoute
  '/new/designer/': typeof NewDesignerIndexRoute
  '/new/founder/': typeof NewFounderIndexRoute
  '/samples': typeof SamplesSamplesIndexRoute
  '/dashboard/admin/designers/$designerId': typeof DashboardAdminDesignersDesignerIdRouteWithChildren
  '/dashboard/admin/founders/$founderId': typeof DashboardAdminFoundersFounderIdRouteWithChildren
  '/dashboard/project/$projectId/assign': typeof DashboardProjectProjectIdAssignRoute
  '/dashboard/project/$projectId/info': typeof DashboardProjectProjectIdInfoRoute
  '/dashboard/project/$projectId/reviews': typeof DashboardProjectProjectIdReviewsRoute
  '/dashboard/project/$projectId/updates': typeof DashboardProjectProjectIdUpdatesRoute
  '/onboarding/creatives/$type/levels': typeof OnboardingCreativesTypeLevelsRoute
  '/onboarding/creatives/$type/time-zone': typeof OnboardingCreativesTypeTimeZoneRoute
  '/dashboard/admin/': typeof DashboardAdminAdminIndexRoute
  '/dashboard/designer/': typeof DashboardDesignerDesignerIndexRoute
  '/dashboard/founder/': typeof DashboardFounderFounderIndexRoute
  '/onboarding/creatives/$type': typeof OnboardingCreativesTypeIndexRoute
  '/dashboard/admin/designers/$designerId/assign': typeof DashboardAdminDesignersDesignerIdAssignRoute
  '/dashboard/admin/designers/$designerId/info': typeof DashboardAdminDesignersDesignerIdInfoRoute
  '/dashboard/admin/designers/$designerId/rate': typeof DashboardAdminDesignersDesignerIdRateRoute
  '/dashboard/admin/founders/$founderId/edit': typeof DashboardAdminFoundersFounderIdEditRoute
  '/dashboard/admin/founders/$founderId/info': typeof DashboardAdminFoundersFounderIdInfoRoute
  '/dashboard/admin/founders/$founderId/invoice': typeof DashboardAdminFoundersFounderIdInvoiceRoute
  '/dashboard/admin/founders/$founderId/proposal': typeof DashboardAdminFoundersFounderIdProposalRoute
  '/dashboard/admin/designers/$designerId/': typeof DashboardAdminDesignersDesignerIdIndexRoute
  '/dashboard/admin/founders': typeof DashboardAdminFoundersFoundersIndexRoute
  '/dashboard/project/$projectId': typeof DashboardProjectProjectIdProjectIdIndexRoute
  '/dashboard/project/$projectId/review/$reviewNumber/create': typeof DashboardProjectProjectIdReviewReviewNumberCreateRoute
  '/dashboard/project/$projectId/review/$reviewNumber/feedback': typeof DashboardProjectProjectIdReviewReviewNumberFeedbackRoute
  '/dashboard/project/$projectId/review/$reviewNumber/next': typeof DashboardProjectProjectIdReviewReviewNumberNextRoute
  '/dashboard/project/$projectId/review/$reviewNumber/rate': typeof DashboardProjectProjectIdReviewReviewNumberRateRoute
  '/dashboard/project/$projectId/update/$updateNumber/create': typeof DashboardProjectProjectIdUpdateUpdateNumberCreateRoute
  '/dashboard/project/$projectId/update/$updateNumber/feedback': typeof DashboardProjectProjectIdUpdateUpdateNumberFeedbackRoute
  '/dashboard/project/$projectId/update/$updateNumber/next': typeof DashboardProjectProjectIdUpdateUpdateNumberNextRoute
  '/dashboard/project/$projectId/update/$updateNumber/rate': typeof DashboardProjectProjectIdUpdateUpdateNumberRateRoute
  '/dashboard/project/$projectId/update/$updateNumber/thanks': typeof DashboardProjectProjectIdUpdateUpdateNumberThanksRoute
  '/dashboard/admin/founders/$founderId/': typeof DashboardAdminFoundersFounderIdFounderIdIndexRoute
  '/dashboard/project/$projectId/review/$reviewNumber': typeof DashboardProjectProjectIdReviewReviewNumberReviewNumberIndexRoute
  '/dashboard/project/$projectId/update/$updateNumber': typeof DashboardProjectProjectIdUpdateUpdateNumberUpdateNumberIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/login': typeof LoginRoute
  '/onboarding': typeof OnboardingRouteWithChildren
  '/start': typeof StartRoute
  '/about': typeof MiscAboutRoute
  '/academy': typeof MiscAcademyRoute
  '/api/redirect': typeof ApiRedirectRoute
  '/dashboard/settings': typeof DashboardSettingsRoute
  '/presentation/creatives': typeof PresentationCreativesRoute
  '/presentation/founder': typeof PresentationFounderRoute
  '/new': typeof NewIndexRoute
  '/confirmed': typeof MiscNewsletterConfirmedRoute
  '/updates': typeof MiscNewsletterUpdatesRoute
  '/about/mission': typeof MiscAboutMissionRoute
  '/academy/story': typeof MiscAcademyStoryRoute
  '/dashboard/admin/academy-members': typeof DashboardAdminAcademyMembersRoute
  '/dashboard/admin/create-founder': typeof DashboardAdminCreateFounderRoute
  '/dashboard/admin/create-invoice': typeof DashboardAdminCreateInvoiceRoute
  '/dashboard/admin/create-project': typeof DashboardAdminCreateProjectRoute
  '/dashboard/admin/creatives': typeof DashboardAdminCreativesRoute
  '/dashboard/admin/site-reports': typeof DashboardAdminSiteReportsRoute
  '/dashboard/designer/settings': typeof DashboardDesignerSettingsRoute
  '/dashboard/founder/payment': typeof DashboardFounderPaymentRoute
  '/new/academy/availability': typeof NewAcademyAvailabilityRoute
  '/new/academy/email': typeof NewAcademyEmailRoute
  '/new/academy/links': typeof NewAcademyLinksRoute
  '/new/academy/samples': typeof NewAcademySamplesRoute
  '/new/academy/services': typeof NewAcademyServicesRoute
  '/new/academy/thank-you': typeof NewAcademyThankYouRoute
  '/new/academy/time-zone': typeof NewAcademyTimeZoneRoute
  '/new/designer/check-mail': typeof NewDesignerCheckMailRoute
  '/new/designer/email': typeof NewDesignerEmailRoute
  '/new/designer/links': typeof NewDesignerLinksRoute
  '/new/designer/samples': typeof NewDesignerSamplesRoute
  '/new/designer/time': typeof NewDesignerTimeRoute
  '/new/founder/check-email': typeof NewFounderCheckEmailRoute
  '/new/founder/company': typeof NewFounderCompanyRoute
  '/new/founder/email': typeof NewFounderEmailRoute
  '/new/founder/outcome': typeof NewFounderOutcomeRoute
  '/new/founder/plans': typeof NewFounderPlansRoute
  '/new/founder/time': typeof NewFounderTimeRoute
  '/dashboard/project': typeof DashboardProjectIndexRoute
  '/new/academy': typeof NewAcademyIndexRoute
  '/new/designer': typeof NewDesignerIndexRoute
  '/new/founder': typeof NewFounderIndexRoute
  '/samples': typeof SamplesSamplesIndexRoute
  '/dashboard/project/$projectId/assign': typeof DashboardProjectProjectIdAssignRoute
  '/dashboard/project/$projectId/info': typeof DashboardProjectProjectIdInfoRoute
  '/dashboard/project/$projectId/reviews': typeof DashboardProjectProjectIdReviewsRoute
  '/dashboard/project/$projectId/updates': typeof DashboardProjectProjectIdUpdatesRoute
  '/onboarding/creatives/$type/levels': typeof OnboardingCreativesTypeLevelsRoute
  '/onboarding/creatives/$type/time-zone': typeof OnboardingCreativesTypeTimeZoneRoute
  '/dashboard/admin': typeof DashboardAdminAdminIndexRoute
  '/dashboard/designer': typeof DashboardDesignerDesignerIndexRoute
  '/dashboard/founder': typeof DashboardFounderFounderIndexRoute
  '/onboarding/creatives/$type': typeof OnboardingCreativesTypeIndexRoute
  '/dashboard/admin/designers/$designerId/assign': typeof DashboardAdminDesignersDesignerIdAssignRoute
  '/dashboard/admin/designers/$designerId/info': typeof DashboardAdminDesignersDesignerIdInfoRoute
  '/dashboard/admin/designers/$designerId/rate': typeof DashboardAdminDesignersDesignerIdRateRoute
  '/dashboard/admin/founders/$founderId/edit': typeof DashboardAdminFoundersFounderIdEditRoute
  '/dashboard/admin/founders/$founderId/info': typeof DashboardAdminFoundersFounderIdInfoRoute
  '/dashboard/admin/founders/$founderId/invoice': typeof DashboardAdminFoundersFounderIdInvoiceRoute
  '/dashboard/admin/founders/$founderId/proposal': typeof DashboardAdminFoundersFounderIdProposalRoute
  '/dashboard/admin/designers/$designerId': typeof DashboardAdminDesignersDesignerIdIndexRoute
  '/dashboard/admin/founders': typeof DashboardAdminFoundersFoundersIndexRoute
  '/dashboard/project/$projectId': typeof DashboardProjectProjectIdProjectIdIndexRoute
  '/dashboard/project/$projectId/review/$reviewNumber/create': typeof DashboardProjectProjectIdReviewReviewNumberCreateRoute
  '/dashboard/project/$projectId/review/$reviewNumber/feedback': typeof DashboardProjectProjectIdReviewReviewNumberFeedbackRoute
  '/dashboard/project/$projectId/review/$reviewNumber/next': typeof DashboardProjectProjectIdReviewReviewNumberNextRoute
  '/dashboard/project/$projectId/review/$reviewNumber/rate': typeof DashboardProjectProjectIdReviewReviewNumberRateRoute
  '/dashboard/project/$projectId/update/$updateNumber/create': typeof DashboardProjectProjectIdUpdateUpdateNumberCreateRoute
  '/dashboard/project/$projectId/update/$updateNumber/feedback': typeof DashboardProjectProjectIdUpdateUpdateNumberFeedbackRoute
  '/dashboard/project/$projectId/update/$updateNumber/next': typeof DashboardProjectProjectIdUpdateUpdateNumberNextRoute
  '/dashboard/project/$projectId/update/$updateNumber/rate': typeof DashboardProjectProjectIdUpdateUpdateNumberRateRoute
  '/dashboard/project/$projectId/update/$updateNumber/thanks': typeof DashboardProjectProjectIdUpdateUpdateNumberThanksRoute
  '/dashboard/admin/founders/$founderId': typeof DashboardAdminFoundersFounderIdFounderIdIndexRoute
  '/dashboard/project/$projectId/review/$reviewNumber': typeof DashboardProjectProjectIdReviewReviewNumberReviewNumberIndexRoute
  '/dashboard/project/$projectId/update/$updateNumber': typeof DashboardProjectProjectIdUpdateUpdateNumberUpdateNumberIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/dashboard': typeof DashboardRouteWithChildren
  '/login': typeof LoginRoute
  '/new': typeof NewRouteWithChildren
  '/onboarding': typeof OnboardingRouteWithChildren
  '/start': typeof StartRoute
  '/_misc/about': typeof MiscAboutRoute
  '/_misc/academy': typeof MiscAcademyRoute
  '/api/redirect': typeof ApiRedirectRoute
  '/dashboard/admin': typeof DashboardAdminRouteWithChildren
  '/dashboard/designer': typeof DashboardDesignerRouteWithChildren
  '/dashboard/founder': typeof DashboardFounderRouteWithChildren
  '/dashboard/project': typeof DashboardProjectRouteWithChildren
  '/dashboard/settings': typeof DashboardSettingsRoute
  '/new/academy': typeof NewAcademyRouteWithChildren
  '/new/designer': typeof NewDesignerRouteWithChildren
  '/new/founder': typeof NewFounderRouteWithChildren
  '/presentation/creatives': typeof PresentationCreativesRoute
  '/presentation/founder': typeof PresentationFounderRoute
  '/new/': typeof NewIndexRoute
  '/_misc/_newsletter/confirmed': typeof MiscNewsletterConfirmedRoute
  '/_misc/_newsletter/updates': typeof MiscNewsletterUpdatesRoute
  '/_misc/about_/mission': typeof MiscAboutMissionRoute
  '/_misc/academy_/story': typeof MiscAcademyStoryRoute
  '/dashboard/admin/academy-members': typeof DashboardAdminAcademyMembersRoute
  '/dashboard/admin/create-founder': typeof DashboardAdminCreateFounderRoute
  '/dashboard/admin/create-invoice': typeof DashboardAdminCreateInvoiceRoute
  '/dashboard/admin/create-project': typeof DashboardAdminCreateProjectRoute
  '/dashboard/admin/creatives': typeof DashboardAdminCreativesRoute
  '/dashboard/admin/site-reports': typeof DashboardAdminSiteReportsRoute
  '/dashboard/designer/settings': typeof DashboardDesignerSettingsRoute
  '/dashboard/founder/payment': typeof DashboardFounderPaymentRoute
  '/new/academy/availability': typeof NewAcademyAvailabilityRoute
  '/new/academy/email': typeof NewAcademyEmailRoute
  '/new/academy/links': typeof NewAcademyLinksRoute
  '/new/academy/samples': typeof NewAcademySamplesRoute
  '/new/academy/services': typeof NewAcademyServicesRoute
  '/new/academy/thank-you': typeof NewAcademyThankYouRoute
  '/new/academy/time-zone': typeof NewAcademyTimeZoneRoute
  '/new/designer/check-mail': typeof NewDesignerCheckMailRoute
  '/new/designer/email': typeof NewDesignerEmailRoute
  '/new/designer/links': typeof NewDesignerLinksRoute
  '/new/designer/samples': typeof NewDesignerSamplesRoute
  '/new/designer/time': typeof NewDesignerTimeRoute
  '/new/founder/check-email': typeof NewFounderCheckEmailRoute
  '/new/founder/company': typeof NewFounderCompanyRoute
  '/new/founder/email': typeof NewFounderEmailRoute
  '/new/founder/outcome': typeof NewFounderOutcomeRoute
  '/new/founder/plans': typeof NewFounderPlansRoute
  '/new/founder/time': typeof NewFounderTimeRoute
  '/dashboard/project/': typeof DashboardProjectIndexRoute
  '/new/academy/': typeof NewAcademyIndexRoute
  '/new/designer/': typeof NewDesignerIndexRoute
  '/new/founder/': typeof NewFounderIndexRoute
  '/samples/_samples/': typeof SamplesSamplesIndexRoute
  '/dashboard/admin/designers/$designerId': typeof DashboardAdminDesignersDesignerIdRouteWithChildren
  '/dashboard/admin/founders/$founderId': typeof DashboardAdminFoundersFounderIdRouteWithChildren
  '/dashboard/project/$projectId/assign': typeof DashboardProjectProjectIdAssignRoute
  '/dashboard/project/$projectId/info': typeof DashboardProjectProjectIdInfoRoute
  '/dashboard/project/$projectId/reviews': typeof DashboardProjectProjectIdReviewsRoute
  '/dashboard/project/$projectId/updates': typeof DashboardProjectProjectIdUpdatesRoute
  '/onboarding/creatives/$type/levels': typeof OnboardingCreativesTypeLevelsRoute
  '/onboarding/creatives/$type/time-zone': typeof OnboardingCreativesTypeTimeZoneRoute
  '/dashboard/admin/_admin/': typeof DashboardAdminAdminIndexRoute
  '/dashboard/designer/_designer/': typeof DashboardDesignerDesignerIndexRoute
  '/dashboard/founder/_founder/': typeof DashboardFounderFounderIndexRoute
  '/onboarding/creatives/$type/': typeof OnboardingCreativesTypeIndexRoute
  '/dashboard/admin/designers/$designerId/assign': typeof DashboardAdminDesignersDesignerIdAssignRoute
  '/dashboard/admin/designers/$designerId/info': typeof DashboardAdminDesignersDesignerIdInfoRoute
  '/dashboard/admin/designers/$designerId/rate': typeof DashboardAdminDesignersDesignerIdRateRoute
  '/dashboard/admin/founders/$founderId/edit': typeof DashboardAdminFoundersFounderIdEditRoute
  '/dashboard/admin/founders/$founderId/info': typeof DashboardAdminFoundersFounderIdInfoRoute
  '/dashboard/admin/founders/$founderId/invoice': typeof DashboardAdminFoundersFounderIdInvoiceRoute
  '/dashboard/admin/founders/$founderId/proposal': typeof DashboardAdminFoundersFounderIdProposalRoute
  '/dashboard/admin/designers/$designerId/': typeof DashboardAdminDesignersDesignerIdIndexRoute
  '/dashboard/admin/founders/_founders/': typeof DashboardAdminFoundersFoundersIndexRoute
  '/dashboard/project/$projectId/_$projectId/': typeof DashboardProjectProjectIdProjectIdIndexRoute
  '/dashboard/project/$projectId/review/$reviewNumber/create': typeof DashboardProjectProjectIdReviewReviewNumberCreateRoute
  '/dashboard/project/$projectId/review/$reviewNumber/feedback': typeof DashboardProjectProjectIdReviewReviewNumberFeedbackRoute
  '/dashboard/project/$projectId/review/$reviewNumber/next': typeof DashboardProjectProjectIdReviewReviewNumberNextRoute
  '/dashboard/project/$projectId/review/$reviewNumber/rate': typeof DashboardProjectProjectIdReviewReviewNumberRateRoute
  '/dashboard/project/$projectId/update/$updateNumber/create': typeof DashboardProjectProjectIdUpdateUpdateNumberCreateRoute
  '/dashboard/project/$projectId/update/$updateNumber/feedback': typeof DashboardProjectProjectIdUpdateUpdateNumberFeedbackRoute
  '/dashboard/project/$projectId/update/$updateNumber/next': typeof DashboardProjectProjectIdUpdateUpdateNumberNextRoute
  '/dashboard/project/$projectId/update/$updateNumber/rate': typeof DashboardProjectProjectIdUpdateUpdateNumberRateRoute
  '/dashboard/project/$projectId/update/$updateNumber/thanks': typeof DashboardProjectProjectIdUpdateUpdateNumberThanksRoute
  '/dashboard/admin/founders/$founderId/_$founderId/': typeof DashboardAdminFoundersFounderIdFounderIdIndexRoute
  '/dashboard/project/$projectId/review/$reviewNumber/_$reviewNumber/': typeof DashboardProjectProjectIdReviewReviewNumberReviewNumberIndexRoute
  '/dashboard/project/$projectId/update/$updateNumber/_$updateNumber/': typeof DashboardProjectProjectIdUpdateUpdateNumberUpdateNumberIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/dashboard'
    | '/login'
    | '/new'
    | '/onboarding'
    | '/start'
    | '/about'
    | '/academy'
    | '/api/redirect'
    | '/dashboard/admin'
    | '/dashboard/designer'
    | '/dashboard/founder'
    | '/dashboard/project'
    | '/dashboard/settings'
    | '/new/academy'
    | '/new/designer'
    | '/new/founder'
    | '/presentation/creatives'
    | '/presentation/founder'
    | '/new/'
    | '/confirmed'
    | '/updates'
    | '/about/mission'
    | '/academy/story'
    | '/dashboard/admin/academy-members'
    | '/dashboard/admin/create-founder'
    | '/dashboard/admin/create-invoice'
    | '/dashboard/admin/create-project'
    | '/dashboard/admin/creatives'
    | '/dashboard/admin/site-reports'
    | '/dashboard/designer/settings'
    | '/dashboard/founder/payment'
    | '/new/academy/availability'
    | '/new/academy/email'
    | '/new/academy/links'
    | '/new/academy/samples'
    | '/new/academy/services'
    | '/new/academy/thank-you'
    | '/new/academy/time-zone'
    | '/new/designer/check-mail'
    | '/new/designer/email'
    | '/new/designer/links'
    | '/new/designer/samples'
    | '/new/designer/time'
    | '/new/founder/check-email'
    | '/new/founder/company'
    | '/new/founder/email'
    | '/new/founder/outcome'
    | '/new/founder/plans'
    | '/new/founder/time'
    | '/dashboard/project/'
    | '/new/academy/'
    | '/new/designer/'
    | '/new/founder/'
    | '/samples'
    | '/dashboard/admin/designers/$designerId'
    | '/dashboard/admin/founders/$founderId'
    | '/dashboard/project/$projectId/assign'
    | '/dashboard/project/$projectId/info'
    | '/dashboard/project/$projectId/reviews'
    | '/dashboard/project/$projectId/updates'
    | '/onboarding/creatives/$type/levels'
    | '/onboarding/creatives/$type/time-zone'
    | '/dashboard/admin/'
    | '/dashboard/designer/'
    | '/dashboard/founder/'
    | '/onboarding/creatives/$type'
    | '/dashboard/admin/designers/$designerId/assign'
    | '/dashboard/admin/designers/$designerId/info'
    | '/dashboard/admin/designers/$designerId/rate'
    | '/dashboard/admin/founders/$founderId/edit'
    | '/dashboard/admin/founders/$founderId/info'
    | '/dashboard/admin/founders/$founderId/invoice'
    | '/dashboard/admin/founders/$founderId/proposal'
    | '/dashboard/admin/designers/$designerId/'
    | '/dashboard/admin/founders'
    | '/dashboard/project/$projectId'
    | '/dashboard/project/$projectId/review/$reviewNumber/create'
    | '/dashboard/project/$projectId/review/$reviewNumber/feedback'
    | '/dashboard/project/$projectId/review/$reviewNumber/next'
    | '/dashboard/project/$projectId/review/$reviewNumber/rate'
    | '/dashboard/project/$projectId/update/$updateNumber/create'
    | '/dashboard/project/$projectId/update/$updateNumber/feedback'
    | '/dashboard/project/$projectId/update/$updateNumber/next'
    | '/dashboard/project/$projectId/update/$updateNumber/rate'
    | '/dashboard/project/$projectId/update/$updateNumber/thanks'
    | '/dashboard/admin/founders/$founderId/'
    | '/dashboard/project/$projectId/review/$reviewNumber'
    | '/dashboard/project/$projectId/update/$updateNumber'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/dashboard'
    | '/login'
    | '/onboarding'
    | '/start'
    | '/about'
    | '/academy'
    | '/api/redirect'
    | '/dashboard/settings'
    | '/presentation/creatives'
    | '/presentation/founder'
    | '/new'
    | '/confirmed'
    | '/updates'
    | '/about/mission'
    | '/academy/story'
    | '/dashboard/admin/academy-members'
    | '/dashboard/admin/create-founder'
    | '/dashboard/admin/create-invoice'
    | '/dashboard/admin/create-project'
    | '/dashboard/admin/creatives'
    | '/dashboard/admin/site-reports'
    | '/dashboard/designer/settings'
    | '/dashboard/founder/payment'
    | '/new/academy/availability'
    | '/new/academy/email'
    | '/new/academy/links'
    | '/new/academy/samples'
    | '/new/academy/services'
    | '/new/academy/thank-you'
    | '/new/academy/time-zone'
    | '/new/designer/check-mail'
    | '/new/designer/email'
    | '/new/designer/links'
    | '/new/designer/samples'
    | '/new/designer/time'
    | '/new/founder/check-email'
    | '/new/founder/company'
    | '/new/founder/email'
    | '/new/founder/outcome'
    | '/new/founder/plans'
    | '/new/founder/time'
    | '/dashboard/project'
    | '/new/academy'
    | '/new/designer'
    | '/new/founder'
    | '/samples'
    | '/dashboard/project/$projectId/assign'
    | '/dashboard/project/$projectId/info'
    | '/dashboard/project/$projectId/reviews'
    | '/dashboard/project/$projectId/updates'
    | '/onboarding/creatives/$type/levels'
    | '/onboarding/creatives/$type/time-zone'
    | '/dashboard/admin'
    | '/dashboard/designer'
    | '/dashboard/founder'
    | '/onboarding/creatives/$type'
    | '/dashboard/admin/designers/$designerId/assign'
    | '/dashboard/admin/designers/$designerId/info'
    | '/dashboard/admin/designers/$designerId/rate'
    | '/dashboard/admin/founders/$founderId/edit'
    | '/dashboard/admin/founders/$founderId/info'
    | '/dashboard/admin/founders/$founderId/invoice'
    | '/dashboard/admin/founders/$founderId/proposal'
    | '/dashboard/admin/designers/$designerId'
    | '/dashboard/admin/founders'
    | '/dashboard/project/$projectId'
    | '/dashboard/project/$projectId/review/$reviewNumber/create'
    | '/dashboard/project/$projectId/review/$reviewNumber/feedback'
    | '/dashboard/project/$projectId/review/$reviewNumber/next'
    | '/dashboard/project/$projectId/review/$reviewNumber/rate'
    | '/dashboard/project/$projectId/update/$updateNumber/create'
    | '/dashboard/project/$projectId/update/$updateNumber/feedback'
    | '/dashboard/project/$projectId/update/$updateNumber/next'
    | '/dashboard/project/$projectId/update/$updateNumber/rate'
    | '/dashboard/project/$projectId/update/$updateNumber/thanks'
    | '/dashboard/admin/founders/$founderId'
    | '/dashboard/project/$projectId/review/$reviewNumber'
    | '/dashboard/project/$projectId/update/$updateNumber'
  id:
    | '__root__'
    | '/'
    | '/dashboard'
    | '/login'
    | '/new'
    | '/onboarding'
    | '/start'
    | '/_misc/about'
    | '/_misc/academy'
    | '/api/redirect'
    | '/dashboard/admin'
    | '/dashboard/designer'
    | '/dashboard/founder'
    | '/dashboard/project'
    | '/dashboard/settings'
    | '/new/academy'
    | '/new/designer'
    | '/new/founder'
    | '/presentation/creatives'
    | '/presentation/founder'
    | '/new/'
    | '/_misc/_newsletter/confirmed'
    | '/_misc/_newsletter/updates'
    | '/_misc/about_/mission'
    | '/_misc/academy_/story'
    | '/dashboard/admin/academy-members'
    | '/dashboard/admin/create-founder'
    | '/dashboard/admin/create-invoice'
    | '/dashboard/admin/create-project'
    | '/dashboard/admin/creatives'
    | '/dashboard/admin/site-reports'
    | '/dashboard/designer/settings'
    | '/dashboard/founder/payment'
    | '/new/academy/availability'
    | '/new/academy/email'
    | '/new/academy/links'
    | '/new/academy/samples'
    | '/new/academy/services'
    | '/new/academy/thank-you'
    | '/new/academy/time-zone'
    | '/new/designer/check-mail'
    | '/new/designer/email'
    | '/new/designer/links'
    | '/new/designer/samples'
    | '/new/designer/time'
    | '/new/founder/check-email'
    | '/new/founder/company'
    | '/new/founder/email'
    | '/new/founder/outcome'
    | '/new/founder/plans'
    | '/new/founder/time'
    | '/dashboard/project/'
    | '/new/academy/'
    | '/new/designer/'
    | '/new/founder/'
    | '/samples/_samples/'
    | '/dashboard/admin/designers/$designerId'
    | '/dashboard/admin/founders/$founderId'
    | '/dashboard/project/$projectId/assign'
    | '/dashboard/project/$projectId/info'
    | '/dashboard/project/$projectId/reviews'
    | '/dashboard/project/$projectId/updates'
    | '/onboarding/creatives/$type/levels'
    | '/onboarding/creatives/$type/time-zone'
    | '/dashboard/admin/_admin/'
    | '/dashboard/designer/_designer/'
    | '/dashboard/founder/_founder/'
    | '/onboarding/creatives/$type/'
    | '/dashboard/admin/designers/$designerId/assign'
    | '/dashboard/admin/designers/$designerId/info'
    | '/dashboard/admin/designers/$designerId/rate'
    | '/dashboard/admin/founders/$founderId/edit'
    | '/dashboard/admin/founders/$founderId/info'
    | '/dashboard/admin/founders/$founderId/invoice'
    | '/dashboard/admin/founders/$founderId/proposal'
    | '/dashboard/admin/designers/$designerId/'
    | '/dashboard/admin/founders/_founders/'
    | '/dashboard/project/$projectId/_$projectId/'
    | '/dashboard/project/$projectId/review/$reviewNumber/create'
    | '/dashboard/project/$projectId/review/$reviewNumber/feedback'
    | '/dashboard/project/$projectId/review/$reviewNumber/next'
    | '/dashboard/project/$projectId/review/$reviewNumber/rate'
    | '/dashboard/project/$projectId/update/$updateNumber/create'
    | '/dashboard/project/$projectId/update/$updateNumber/feedback'
    | '/dashboard/project/$projectId/update/$updateNumber/next'
    | '/dashboard/project/$projectId/update/$updateNumber/rate'
    | '/dashboard/project/$projectId/update/$updateNumber/thanks'
    | '/dashboard/admin/founders/$founderId/_$founderId/'
    | '/dashboard/project/$projectId/review/$reviewNumber/_$reviewNumber/'
    | '/dashboard/project/$projectId/update/$updateNumber/_$updateNumber/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  DashboardRoute: typeof DashboardRouteWithChildren
  LoginRoute: typeof LoginRoute
  NewRoute: typeof NewRouteWithChildren
  OnboardingRoute: typeof OnboardingRouteWithChildren
  StartRoute: typeof StartRoute
  MiscAboutRoute: typeof MiscAboutRoute
  MiscAcademyRoute: typeof MiscAcademyRoute
  ApiRedirectRoute: typeof ApiRedirectRoute
  PresentationCreativesRoute: typeof PresentationCreativesRoute
  PresentationFounderRoute: typeof PresentationFounderRoute
  MiscNewsletterConfirmedRoute: typeof MiscNewsletterConfirmedRoute
  MiscNewsletterUpdatesRoute: typeof MiscNewsletterUpdatesRoute
  MiscAboutMissionRoute: typeof MiscAboutMissionRoute
  MiscAcademyStoryRoute: typeof MiscAcademyStoryRoute
  SamplesSamplesIndexRoute: typeof SamplesSamplesIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  DashboardRoute: DashboardRouteWithChildren,
  LoginRoute: LoginRoute,
  NewRoute: NewRouteWithChildren,
  OnboardingRoute: OnboardingRouteWithChildren,
  StartRoute: StartRoute,
  MiscAboutRoute: MiscAboutRoute,
  MiscAcademyRoute: MiscAcademyRoute,
  ApiRedirectRoute: ApiRedirectRoute,
  PresentationCreativesRoute: PresentationCreativesRoute,
  PresentationFounderRoute: PresentationFounderRoute,
  MiscNewsletterConfirmedRoute: MiscNewsletterConfirmedRoute,
  MiscNewsletterUpdatesRoute: MiscNewsletterUpdatesRoute,
  MiscAboutMissionRoute: MiscAboutMissionRoute,
  MiscAcademyStoryRoute: MiscAcademyStoryRoute,
  SamplesSamplesIndexRoute: SamplesSamplesIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/dashboard",
        "/login",
        "/new",
        "/onboarding",
        "/start",
        "/_misc/about",
        "/_misc/academy",
        "/api/redirect",
        "/presentation/creatives",
        "/presentation/founder",
        "/_misc/_newsletter/confirmed",
        "/_misc/_newsletter/updates",
        "/_misc/about_/mission",
        "/_misc/academy_/story",
        "/samples/_samples/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/dashboard": {
      "filePath": "dashboard.tsx",
      "children": [
        "/dashboard/admin",
        "/dashboard/designer",
        "/dashboard/founder",
        "/dashboard/project",
        "/dashboard/settings"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/new": {
      "filePath": "new.tsx",
      "children": [
        "/new/academy",
        "/new/designer",
        "/new/founder",
        "/new/"
      ]
    },
    "/onboarding": {
      "filePath": "onboarding.tsx",
      "children": [
        "/onboarding/creatives/$type/levels",
        "/onboarding/creatives/$type/time-zone",
        "/onboarding/creatives/$type/"
      ]
    },
    "/start": {
      "filePath": "start.tsx"
    },
    "/_misc/about": {
      "filePath": "_misc/about.tsx"
    },
    "/_misc/academy": {
      "filePath": "_misc/academy.tsx"
    },
    "/api/redirect": {
      "filePath": "api/redirect.tsx"
    },
    "/dashboard/admin": {
      "filePath": "dashboard/admin.tsx",
      "parent": "/dashboard",
      "children": [
        "/dashboard/admin/academy-members",
        "/dashboard/admin/create-founder",
        "/dashboard/admin/create-invoice",
        "/dashboard/admin/create-project",
        "/dashboard/admin/creatives",
        "/dashboard/admin/site-reports",
        "/dashboard/admin/designers/$designerId",
        "/dashboard/admin/founders/$founderId",
        "/dashboard/admin/_admin/",
        "/dashboard/admin/founders/_founders/"
      ]
    },
    "/dashboard/designer": {
      "filePath": "dashboard/designer.tsx",
      "parent": "/dashboard",
      "children": [
        "/dashboard/designer/settings",
        "/dashboard/designer/_designer/"
      ]
    },
    "/dashboard/founder": {
      "filePath": "dashboard/founder.tsx",
      "parent": "/dashboard",
      "children": [
        "/dashboard/founder/payment",
        "/dashboard/founder/_founder/"
      ]
    },
    "/dashboard/project": {
      "filePath": "dashboard/project.tsx",
      "parent": "/dashboard",
      "children": [
        "/dashboard/project/",
        "/dashboard/project/$projectId/assign",
        "/dashboard/project/$projectId/info",
        "/dashboard/project/$projectId/reviews",
        "/dashboard/project/$projectId/updates",
        "/dashboard/project/$projectId/_$projectId/",
        "/dashboard/project/$projectId/review/$reviewNumber/create",
        "/dashboard/project/$projectId/review/$reviewNumber/feedback",
        "/dashboard/project/$projectId/review/$reviewNumber/next",
        "/dashboard/project/$projectId/review/$reviewNumber/rate",
        "/dashboard/project/$projectId/update/$updateNumber/create",
        "/dashboard/project/$projectId/update/$updateNumber/feedback",
        "/dashboard/project/$projectId/update/$updateNumber/next",
        "/dashboard/project/$projectId/update/$updateNumber/rate",
        "/dashboard/project/$projectId/update/$updateNumber/thanks",
        "/dashboard/project/$projectId/review/$reviewNumber/_$reviewNumber/",
        "/dashboard/project/$projectId/update/$updateNumber/_$updateNumber/"
      ]
    },
    "/dashboard/settings": {
      "filePath": "dashboard/settings.tsx",
      "parent": "/dashboard"
    },
    "/new/academy": {
      "filePath": "new/academy.tsx",
      "parent": "/new",
      "children": [
        "/new/academy/availability",
        "/new/academy/email",
        "/new/academy/links",
        "/new/academy/samples",
        "/new/academy/services",
        "/new/academy/thank-you",
        "/new/academy/time-zone",
        "/new/academy/"
      ]
    },
    "/new/designer": {
      "filePath": "new/designer.tsx",
      "parent": "/new",
      "children": [
        "/new/designer/check-mail",
        "/new/designer/email",
        "/new/designer/links",
        "/new/designer/samples",
        "/new/designer/time",
        "/new/designer/"
      ]
    },
    "/new/founder": {
      "filePath": "new/founder.tsx",
      "parent": "/new",
      "children": [
        "/new/founder/check-email",
        "/new/founder/company",
        "/new/founder/email",
        "/new/founder/outcome",
        "/new/founder/plans",
        "/new/founder/time",
        "/new/founder/"
      ]
    },
    "/presentation/creatives": {
      "filePath": "presentation.creatives.tsx"
    },
    "/presentation/founder": {
      "filePath": "presentation.founder.tsx"
    },
    "/new/": {
      "filePath": "new/index.tsx",
      "parent": "/new"
    },
    "/_misc/_newsletter/confirmed": {
      "filePath": "_misc/_newsletter.confirmed.tsx"
    },
    "/_misc/_newsletter/updates": {
      "filePath": "_misc/_newsletter.updates.tsx"
    },
    "/_misc/about_/mission": {
      "filePath": "_misc/about_.mission.tsx"
    },
    "/_misc/academy_/story": {
      "filePath": "_misc/academy_.story.tsx"
    },
    "/dashboard/admin/academy-members": {
      "filePath": "dashboard/admin/academy-members.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/create-founder": {
      "filePath": "dashboard/admin/create-founder.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/create-invoice": {
      "filePath": "dashboard/admin/create-invoice.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/create-project": {
      "filePath": "dashboard/admin/create-project.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/creatives": {
      "filePath": "dashboard/admin/creatives.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/admin/site-reports": {
      "filePath": "dashboard/admin/site-reports.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/designer/settings": {
      "filePath": "dashboard/designer/settings.tsx",
      "parent": "/dashboard/designer"
    },
    "/dashboard/founder/payment": {
      "filePath": "dashboard/founder/payment.tsx",
      "parent": "/dashboard/founder"
    },
    "/new/academy/availability": {
      "filePath": "new/academy/availability.tsx",
      "parent": "/new/academy"
    },
    "/new/academy/email": {
      "filePath": "new/academy/email.tsx",
      "parent": "/new/academy"
    },
    "/new/academy/links": {
      "filePath": "new/academy/links.tsx",
      "parent": "/new/academy"
    },
    "/new/academy/samples": {
      "filePath": "new/academy/samples.tsx",
      "parent": "/new/academy"
    },
    "/new/academy/services": {
      "filePath": "new/academy/services.tsx",
      "parent": "/new/academy"
    },
    "/new/academy/thank-you": {
      "filePath": "new/academy/thank-you.tsx",
      "parent": "/new/academy"
    },
    "/new/academy/time-zone": {
      "filePath": "new/academy/time-zone.tsx",
      "parent": "/new/academy"
    },
    "/new/designer/check-mail": {
      "filePath": "new/designer/check-mail.tsx",
      "parent": "/new/designer"
    },
    "/new/designer/email": {
      "filePath": "new/designer/email.tsx",
      "parent": "/new/designer"
    },
    "/new/designer/links": {
      "filePath": "new/designer/links.tsx",
      "parent": "/new/designer"
    },
    "/new/designer/samples": {
      "filePath": "new/designer/samples.tsx",
      "parent": "/new/designer"
    },
    "/new/designer/time": {
      "filePath": "new/designer/time.tsx",
      "parent": "/new/designer"
    },
    "/new/founder/check-email": {
      "filePath": "new/founder/check-email.tsx",
      "parent": "/new/founder"
    },
    "/new/founder/company": {
      "filePath": "new/founder/company.tsx",
      "parent": "/new/founder"
    },
    "/new/founder/email": {
      "filePath": "new/founder/email.tsx",
      "parent": "/new/founder"
    },
    "/new/founder/outcome": {
      "filePath": "new/founder/outcome.tsx",
      "parent": "/new/founder"
    },
    "/new/founder/plans": {
      "filePath": "new/founder/plans.tsx",
      "parent": "/new/founder"
    },
    "/new/founder/time": {
      "filePath": "new/founder/time.tsx",
      "parent": "/new/founder"
    },
    "/dashboard/project/": {
      "filePath": "dashboard/project/index.tsx",
      "parent": "/dashboard/project"
    },
    "/new/academy/": {
      "filePath": "new/academy/index.tsx",
      "parent": "/new/academy"
    },
    "/new/designer/": {
      "filePath": "new/designer/index.tsx",
      "parent": "/new/designer"
    },
    "/new/founder/": {
      "filePath": "new/founder/index.tsx",
      "parent": "/new/founder"
    },
    "/samples/_samples/": {
      "filePath": "samples/_samples.index.tsx"
    },
    "/dashboard/admin/designers/$designerId": {
      "filePath": "dashboard/admin/designers.$designerId.tsx",
      "parent": "/dashboard/admin",
      "children": [
        "/dashboard/admin/designers/$designerId/assign",
        "/dashboard/admin/designers/$designerId/info",
        "/dashboard/admin/designers/$designerId/rate",
        "/dashboard/admin/designers/$designerId/"
      ]
    },
    "/dashboard/admin/founders/$founderId": {
      "filePath": "dashboard/admin/founders/$founderId.tsx",
      "parent": "/dashboard/admin",
      "children": [
        "/dashboard/admin/founders/$founderId/edit",
        "/dashboard/admin/founders/$founderId/info",
        "/dashboard/admin/founders/$founderId/invoice",
        "/dashboard/admin/founders/$founderId/proposal",
        "/dashboard/admin/founders/$founderId/_$founderId/"
      ]
    },
    "/dashboard/project/$projectId/assign": {
      "filePath": "dashboard/project/$projectId/assign.tsx",
      "parent": "/dashboard/project"
    },
    "/dashboard/project/$projectId/info": {
      "filePath": "dashboard/project/$projectId/info.tsx",
      "parent": "/dashboard/project"
    },
    "/dashboard/project/$projectId/reviews": {
      "filePath": "dashboard/project/$projectId/reviews.tsx",
      "parent": "/dashboard/project"
    },
    "/dashboard/project/$projectId/updates": {
      "filePath": "dashboard/project/$projectId/updates.tsx",
      "parent": "/dashboard/project"
    },
    "/onboarding/creatives/$type/levels": {
      "filePath": "onboarding/creatives.$type/levels.tsx",
      "parent": "/onboarding"
    },
    "/onboarding/creatives/$type/time-zone": {
      "filePath": "onboarding/creatives.$type/time-zone.tsx",
      "parent": "/onboarding"
    },
    "/dashboard/admin/_admin/": {
      "filePath": "dashboard/admin/_admin.index.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/designer/_designer/": {
      "filePath": "dashboard/designer/_designer.index.tsx",
      "parent": "/dashboard/designer"
    },
    "/dashboard/founder/_founder/": {
      "filePath": "dashboard/founder/_founder.index.tsx",
      "parent": "/dashboard/founder"
    },
    "/onboarding/creatives/$type/": {
      "filePath": "onboarding/creatives.$type/index.tsx",
      "parent": "/onboarding"
    },
    "/dashboard/admin/designers/$designerId/assign": {
      "filePath": "dashboard/admin/designers.$designerId/assign.tsx",
      "parent": "/dashboard/admin/designers/$designerId"
    },
    "/dashboard/admin/designers/$designerId/info": {
      "filePath": "dashboard/admin/designers.$designerId/info.tsx",
      "parent": "/dashboard/admin/designers/$designerId"
    },
    "/dashboard/admin/designers/$designerId/rate": {
      "filePath": "dashboard/admin/designers.$designerId/rate.tsx",
      "parent": "/dashboard/admin/designers/$designerId"
    },
    "/dashboard/admin/founders/$founderId/edit": {
      "filePath": "dashboard/admin/founders/$founderId/edit.tsx",
      "parent": "/dashboard/admin/founders/$founderId"
    },
    "/dashboard/admin/founders/$founderId/info": {
      "filePath": "dashboard/admin/founders/$founderId/info.tsx",
      "parent": "/dashboard/admin/founders/$founderId"
    },
    "/dashboard/admin/founders/$founderId/invoice": {
      "filePath": "dashboard/admin/founders/$founderId/invoice.tsx",
      "parent": "/dashboard/admin/founders/$founderId"
    },
    "/dashboard/admin/founders/$founderId/proposal": {
      "filePath": "dashboard/admin/founders/$founderId/proposal.tsx",
      "parent": "/dashboard/admin/founders/$founderId"
    },
    "/dashboard/admin/designers/$designerId/": {
      "filePath": "dashboard/admin/designers.$designerId/index.tsx",
      "parent": "/dashboard/admin/designers/$designerId"
    },
    "/dashboard/admin/founders/_founders/": {
      "filePath": "dashboard/admin/founders/_founders.index.tsx",
      "parent": "/dashboard/admin"
    },
    "/dashboard/project/$projectId/_$projectId/": {
      "filePath": "dashboard/project/$projectId/_$projectId.index.tsx",
      "parent": "/dashboard/project"
    },
    "/dashboard/project/$projectId/review/$reviewNumber/create": {
      "filePath": "dashboard/project/$projectId/review.$reviewNumber/create.tsx",
      "parent": "/dashboard/project"
    },
    "/dashboard/project/$projectId/review/$reviewNumber/feedback": {
      "filePath": "dashboard/project/$projectId/review.$reviewNumber/feedback.tsx",
      "parent": "/dashboard/project"
    },
    "/dashboard/project/$projectId/review/$reviewNumber/next": {
      "filePath": "dashboard/project/$projectId/review.$reviewNumber/next.tsx",
      "parent": "/dashboard/project"
    },
    "/dashboard/project/$projectId/review/$reviewNumber/rate": {
      "filePath": "dashboard/project/$projectId/review.$reviewNumber/rate.tsx",
      "parent": "/dashboard/project"
    },
    "/dashboard/project/$projectId/update/$updateNumber/create": {
      "filePath": "dashboard/project/$projectId/update.$updateNumber/create.tsx",
      "parent": "/dashboard/project"
    },
    "/dashboard/project/$projectId/update/$updateNumber/feedback": {
      "filePath": "dashboard/project/$projectId/update.$updateNumber/feedback.tsx",
      "parent": "/dashboard/project"
    },
    "/dashboard/project/$projectId/update/$updateNumber/next": {
      "filePath": "dashboard/project/$projectId/update.$updateNumber/next.tsx",
      "parent": "/dashboard/project"
    },
    "/dashboard/project/$projectId/update/$updateNumber/rate": {
      "filePath": "dashboard/project/$projectId/update.$updateNumber/rate.tsx",
      "parent": "/dashboard/project"
    },
    "/dashboard/project/$projectId/update/$updateNumber/thanks": {
      "filePath": "dashboard/project/$projectId/update.$updateNumber/thanks.tsx",
      "parent": "/dashboard/project"
    },
    "/dashboard/admin/founders/$founderId/_$founderId/": {
      "filePath": "dashboard/admin/founders/$founderId/_$founderId.index.tsx",
      "parent": "/dashboard/admin/founders/$founderId"
    },
    "/dashboard/project/$projectId/review/$reviewNumber/_$reviewNumber/": {
      "filePath": "dashboard/project/$projectId/review.$reviewNumber/_$reviewNumber.index.tsx",
      "parent": "/dashboard/project"
    },
    "/dashboard/project/$projectId/update/$updateNumber/_$updateNumber/": {
      "filePath": "dashboard/project/$projectId/update.$updateNumber/_$updateNumber.index.tsx",
      "parent": "/dashboard/project"
    }
  }
}
ROUTE_MANIFEST_END */
