import {
  BookingType as _BookingType,
  FounderPlan,
  FounderService,
  Prisma,
} from '@prisma/client';

export type { Option, OptionPack } from '@prisma/client';

export type Founder = Prisma.FounderGetPayload<{
  include: {
    optionsPack: {
      include: { options: true };
    };
    user: true;
    bookings: true;
    selectedOption: true;
    invoice: true;
    project: true;
  };
}>;

export type Service = keyof typeof FounderService;
export type Plan = keyof typeof FounderPlan;

export const SERVICES = [
  'BRAND_S',
  'BRAND_M',
  'BRAND_L',

  'WEBSITE_S',
  'WEBSITE_M',
  'WEBSITE_L',

  'PRODUCT_S',
  'PRODUCT_M',
  'PRODUCT_L',
] as const;

export const SERVICE_PRICES: Record<Service, number> = {
  BRAND_S: 10_000,
  BRAND_M: 20_000,
  BRAND_L: 30_000,

  WEBSITE_S: 10_000,
  WEBSITE_M: 20_000,
  WEBSITE_L: 30_000,

  PRODUCT_S: 10_000,
  PRODUCT_M: 20_000,
  PRODUCT_L: 30_000,
};

export const PLAN_WITH_SERVICES: Record<Plan, Service[]> = {
  ESSENTIAL: ['BRAND_S', 'WEBSITE_S', 'PRODUCT_S'],
  EXTENDED: ['BRAND_M', 'WEBSITE_M', 'PRODUCT_M'],
  BEYOND: ['BRAND_L', 'WEBSITE_L', 'PRODUCT_L'],
};

export const SERVICE_DESCRIPTIONS: Record<Service, string> = {
  BRAND_S: 'New identity with essential assets.',
  BRAND_M: '+ Illustrations and imagery.',
  BRAND_L: '+ Advanced 3D and motion.',
  WEBSITE_S: '1-2 pages',
  WEBSITE_M: '3-5 pages',
  WEBSITE_L: '6+ pages',
  PRODUCT_S: 'Single Purpose',
  PRODUCT_M: 'Multi Purpose',
  PRODUCT_L: 'Multi Platform',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ServiceType = Service extends `${infer T}_${infer _}` ? T : never;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ServiceSize = Service extends `${infer _}_${infer S}` ? S : never;

// This is a type assertion to ensure that the sync between the database type and the code is correct
// You can see the SERVICE array is defined as a const array of strings, because Zod only support this way of defining enums
// This variable will fail to compile if the database type changes and the code is not updated :)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _TS_CHECK = SERVICES satisfies readonly Service[];

export type BookingType = _BookingType;
