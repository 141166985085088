import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { createFileRoute, redirect } from '@tanstack/react-router';

import { trpcClientUtils, trpcVanilla } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { toast } from '@/lib/utils/toast';

const stripePromise = loadStripe(
  'pk_test_51QMtfPJkIpkt15d9vNRioG4VmRDmch7ugqAaLWhlbw3WP5FCHff3B6rq36nxLLOEynXvmpnb4HyjOi4PIPcb4GZc00BjbZVJvI',
);

export const Route = createFileRoute('/dashboard/founder/payment')({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      session_id: search.session_id as string | undefined,
    };
  },
  loaderDeps: ({ search: { session_id } }) => ({ session_id }),
  loader: async ({ deps }) => {
    const founder = await trpcClientUtils.founder.me.fetch();
    if (!founder.payment_session_id) {
      toast('Payment session not found, please contact support');
      throw redirect({ to: '/', replace: true });
    }

    if (deps.session_id) {
      // try to onboard the founder
      // this will throw if the session is not valid or not paid
      try {
        const { payment_status } = await trpcVanilla.founder.onboard.mutate();
        if (payment_status !== 'paid') {
          toast('Payment not completed');
          throw redirect({
            to: '/dashboard/founder',
            replace: true,
          });
        }
        toast('Payment completed successfully');
        throw redirect({
          to: '/dashboard/founder',
          replace: true,
        });
      } catch (err) {
        const error = err as Error;
        toast(error.message);
      }
    }

    const paymentStatus = await trpcClientUtils.founder.paymentStatus.fetch({
      session_id: founder.payment_session_id,
    });

    if (paymentStatus.status === 'paid') {
      throw redirect({
        to: '/dashboard/founder',
        replace: true,
      });
    }

    return { founder, paymentStatus };
  },
  staleTime: 0,
  gcTime: 0,
  component: RouteComponent,
});

function RouteComponent() {
  const { founder, paymentStatus } = Route.useLoaderData();

  return (
    <section className="flex flex-col items-center">
      <h1>
        {founder.name}, to confirm your project, please make the payment.
        <br className="hidden md:block" /> We will refund it in full if the
        project is not a fit.
      </h1>
      <div className="mt-20 w-full pb-40 md:w-[500px]">
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret: paymentStatus.clientSecret }}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>

      <BottomNavigation left={<BackButton to="/new/founder/plans" />} />
    </section>
  );
}
