import * as Sentry from '@sentry/react';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { AxiosError } from 'axios';

import { trpcClientUtils, trpcVanilla } from '@/api/trpc';
import { Spinner } from '@/components/spinner';
import { toast } from '@/lib/utils/toast';

export const Route = createFileRoute('/api/redirect')({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      token: search.token as string,
      nextPage: search.nextPage as string | undefined,
    };
  },
  beforeLoad: async ({ search: { token, nextPage } }) => {
    if (!token) {
      return redirect({ to: '/' });
    }

    try {
      const ssoData = await trpcVanilla.auth.consumeSSO.mutate({
        token: token,
      });

      if (!ssoData) {
        return redirect({ to: '/' });
      }

      const cookieStore = window.localStorage;

      cookieStore.setItem('accessToken', ssoData.access_token);
      cookieStore.setItem('refreshToken', ssoData.refresh_token);
      cookieStore.setItem('userType', ssoData.user_type);
      cookieStore.setItem('email', ssoData.email);

      switch (ssoData.user_type) {
        case 'FOUNDER': {
          return redirect({
            to: nextPage || '/dashboard/founder',
            replace: true,
          });
        }

        case 'ADMIN':
          return redirect({
            to: '/dashboard/admin',
            search: {
              filter: undefined,
            },
            replace: true,
          });

        case 'DESIGNER': {
          const designer = await trpcClientUtils.designer.me.fetch();

          if (designer.status === 'ONBOARDING') {
            return redirect({
              to: '/onboarding/creatives/$type',
              replace: true,
              params: { type: 'designer' },
            });
          } else {
            return redirect({
              to: nextPage || '/dashboard/designer',
              replace: true,
            });
          }
        }
        default:
          throw new Error(
            `Unknown user type: ${ssoData.user_type}. Or not implemented.`,
          );
      }
    } catch (error) {
      Sentry.captureException(error);
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          toast('Invalid token. Please try again.');
          return redirect({ to: '/login', replace: true });
        }
      }

      toast('An error occurred. Please try again.');
      localStorage.clear();
      return redirect({ to: '/', replace: true });
    }
  },
  pendingComponent: () => {
    return (
      <div className="grid h-[100dvh] w-[100dvw] place-items-center">
        <Spinner />
      </div>
    );
  },
  gcTime: 0,
  staleTime: 0,
});
