import { Designer } from '@op-platform/backend/designer';
import { useInfiniteQuery } from '@tanstack/react-query';
import { createFileRoute, Link } from '@tanstack/react-router';
import { motion } from 'motion/react';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { trpcClientUtils } from '@/api/trpc';
import { DesignerListItem } from '@/components/admin/designer-item';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { Spinner } from '@/components/spinner';

import { Filter } from './_admin.index';

export const CREATIVE_FILTERS: Record<Filter, Designer['status'][]> = {
  next: [
    'ONBOARDING',
    'ONBOARDED',
    'INTRO_CALL_REQUEST_SENT',
    'INTRO_CALL_BOOKED',
    'INTRO_CALL_STARTED',
    'INTRO_CALL_ENDED',
  ],
  past: ['REJECTED'],
  present: ['PROJECT_ASSIGNED'],
};

export const Route = createFileRoute('/dashboard/admin/academy-members')({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      filter: search.filter as Filter | undefined,
      style: search.style as string | undefined,
    };
  },
  loaderDeps: ({ search: { filter } }) => ({ filter }),
  loader: async ({ deps: { filter } }) => {
    const statuses = filter ? CREATIVE_FILTERS[filter] : CREATIVE_FILTERS.next;
    const academyMembers = await trpcClientUtils.admin.academyMember.list.fetch(
      {
        statuses,
        limit: 200,
        offset: 0,
      },
    );

    const countList =
      await trpcClientUtils.admin.academyMember.statusCounts.fetch();

    return { academyMembers, countList };
  },
  pendingComponent: () => (
    <div className="grid h-full w-full place-items-center">
      <Spinner />
    </div>
  ),
  component: function Page() {
    const { academyMembers, countList } = Route.useLoaderData();
    const { filter, style } = Route.useSearch();
    const { ref, inView } = useInView();

    const statuses = filter ? CREATIVE_FILTERS[filter] : CREATIVE_FILTERS.next;
    const filters = CREATIVE_FILTERS[filter as keyof typeof CREATIVE_FILTERS];

    const currentFilterTotalCount = Object.entries(countList).reduce(
      (prevItem, [key, value]) => {
        if (filters.includes(key as Designer['status'])) {
          return prevItem + value;
        } else {
          return prevItem;
        }
      },
      0,
    );

    const designersQuery = useInfiniteQuery({
      queryKey: ['academy-members', statuses],
      queryFn: ({ pageParam }) => {
        return trpcClientUtils.admin.academyMember.list.fetch({
          statuses,
          limit: 200,
          offset: pageParam,
        });
      },
      initialData: () => ({
        pageParams: [0],
        pages: [academyMembers],
      }),
      initialPageParam: 0,
      getNextPageParam: (_, allPages) => {
        const total = allPages.flat().length;
        if (total < currentFilterTotalCount) {
          return total;
        }

        return undefined;
      },
    });

    const flatDesigners = designersQuery.data.pages.flat();

    useEffect(() => {
      if (inView) {
        designersQuery.fetchNextPage();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView, designersQuery.data.pageParams]);

    const renderContent = () => {
      if (style === 'expanded') {
        const samples = flatDesigners
          .map(designer => {
            return designer.samples.map(sample => {
              return {
                url: sample,
                designer: designer,
              };
            });
          })
          .flat();
        return (
          <div className="grid grid-cols-3 gap-4 p-4 pt-20">
            {samples.map(({ designer, url }, index) => {
              return (
                <Link
                  to="/dashboard/admin/designers/$designerId"
                  params={{ designerId: String(designer.id) }}
                  resetScroll={false}
                  key={`${designer.id}-${url}-${index}`}
                  className="gap flex flex-col items-center justify-center gap-2">
                  <div className="flex w-full overflow-hidden rounded-lg border border-gray-200">
                    <img
                      className="h-auto w-full"
                      src={url}
                      alt={designer.name ?? 'Designer'}
                    />
                  </div>
                  <h1>{designer.name}</h1>
                </Link>
              );
            })}
          </div>
        );
      }

      return (
        <div className="flex flex-wrap justify-center gap-4 pt-20">
          {flatDesigners.map((designer, index) => (
            <motion.div
              key={designer.id}
              animate={{ y: 0, opacity: 1, scale: 1 }}
              initial={{ y: 10, opacity: 0, scale: 0.8 }}
              transition={{
                duration: 0.2,
                delay: index <= 30 ? index * 0.05 : 0,
              }}>
              <DesignerListItem designer={designer} key={designer.id} />
            </motion.div>
          ))}
        </div>
      );
    };

    return (
      <div className="flex flex-col items-center">
        <span>{currentFilterTotalCount} Creatives</span>
        <Link
          to="/dashboard/admin/academy-members"
          search={{
            filter: 'next',
            style: style === 'expanded' ? undefined : 'expanded',
          }}
          className="text-sm text-gray-900 underline">
          {style === 'expanded' ? 'View as list' : 'View as grid'}
        </Link>

        {renderContent()}
        {designersQuery.hasNextPage && (
          <div ref={ref} className="mt-10 text-center">
            <Spinner />
          </div>
        )}

        <BottomNavigation left={<BackButton search={{ filter }} />} />
      </div>
    );
  },
});
