import { createFileRoute, redirect } from '@tanstack/react-router';
import umami from '@umami/node';

import { trpc } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { useUmamiPageEvent } from '@/lib/umami';
import { getUserTypeFromLocalStorage } from '@/lib/utils';
import {
  FounderNewState,
  founderNewState$,
  isFounderNewCompleted$,
} from '@/store/founder.state';

export const Route = createFileRoute('/new/founder/time')({
  beforeLoad: () => {
    if (!isFounderNewCompleted$.peek()) {
      return redirect({ to: '/new/founder' });
    }
  },
  component: function Page() {
    const kickOffDate = founderNewState$.estimated_start_date.get();
    const navigate = Route.useNavigate();
    useUmamiPageEvent('Founder - When would you like to begin?');

    const createFounderStatus = trpc.founder.create.useMutation();
    const changePaymentMutation = trpc.founder.changePayment.useMutation();

    const handleSubmit = async () => {
      const founder = founderNewState$.get();
      const services = founder.requested_option.services.map(s => s.full);

      const localAuth = getUserTypeFromLocalStorage();

      if (localAuth.isAuthenticated) {
        // if the user wants to change their plan, we need to update the plan in backend
        await changePaymentMutation.mutateAsync({
          type: 'REQUESTED',
          plan: founder.requested_option.plan,
          services: [],
        });

        return navigate({
          to: '/api/redirect',
          search: {
            token: localAuth.accessToken!,
            nextPage: undefined,
          },
          replace: true,
          
        });
      } else {
        const res = await createFounderStatus.mutateAsync({
          email: founder.email,
          name: founder.name,
          company_name: founder.company_name,
          estimated_start_date: founder.estimated_start_date!,
          project_description: founder.project_outcome_description,
          requested_option: {
            type: 'REQUESTED',
            plan: founder.requested_option.plan,
            services: services.length > 0 ? services : undefined,
          },
        });

        return navigate({
          to: '/api/redirect',
          search: {
            token: res.ssoToken,
            nextPage: undefined,
          },
          replace: true,
        });
      }
    };

    return (
      <div>
        <section className="flex flex-col items-center justify-center text-center">
          <h1>When would you like to start?</h1>

          <ToggleGroup
            className="mt-10 md:mt-20"
            type="single"
            defaultValue={kickOffDate!}
            onValueChange={value => {
              if (value) {
                founderNewState$.estimated_start_date.set(
                  value as FounderNewState['estimated_start_date'],
                );
                umami.track('Founder Answer - When would you like to begin?', {
                  KickoffDate: value,
                });
                handleSubmit();
              }
            }}>
            <ToggleGroupItem value="NEXT_WEEK">Next Week</ToggleGroupItem>
            <ToggleGroupItem value="NEXT_MONTH">Next Month</ToggleGroupItem>
            <ToggleGroupItem value="LATER">Later</ToggleGroupItem>
          </ToggleGroup>
        </section>

        <BottomNavigation
          left={<BackButton loading={createFounderStatus.isPending} native />}
        />
      </div>
    );
  },
});
