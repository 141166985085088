import { createRouter } from '@tanstack/react-router';

import { queryClient } from '@/lib/query-client';

import { Spinner } from './components/spinner';
import { routeTree } from './routeTree.gen';

// Set up a Router instance
export const appRouter = createRouter({
  routeTree,
  defaultPreload: 'intent',
  scrollRestoration: true,
  defaultPendingComponent: () => (
    <div className="grid h-[100dvh] w-[100dvw] place-items-center">
      <Spinner />
    </div>
  ),
  context: {
    queryClient,
  },
});

// Register things for typesafety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof appRouter;
  }
}
