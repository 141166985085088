import { createFileRoute, Link } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { motion } from 'motion/react';
import Markdown from 'react-markdown';

import { trpc, trpcClientUtils } from '@/api/trpc';
import { BackButton } from '@/components/back-button';
import { BottomNavigation } from '@/components/bottom-navigation';
import { CopyEmailButton } from '@/components/copy-email-button';
import {
  FeatureContainer,
  FeatureDot,
  FeatureGroup,
} from '@/components/feature-groups';
import { FoundersTag } from '@/components/founders-tag';
import { AssignUser } from '@/components/project/AssignUser';
import { Spinner } from '@/components/spinner';
import { Button } from '@/components/ui/button';
import { useOption } from '@/hooks/use-options';
import { useProject } from '@/hooks/use-project';
import { useTimeZoneClock } from '@/hooks/use-time-zone-clock';
import {
  formatTimezone,
  getUserTypeFromLocalStorage,
  priceFormatter,
  sizeToMonth,
  sizeToMountCount,
} from '@/lib/utils';

export const Route = createFileRoute('/dashboard/project/$projectId/info')({
  loader: async ({ params }) => {
    const project = await trpcClientUtils.project.get.fetch({
      id: Number(params.projectId),
    });

    return { project };
  },
  gcTime: 0,
  staleTime: 0,
  component: RouteComponent,
});

function RouteComponent() {
  const { project } = Route.useLoaderData();
  const founder = project.founder;
  const time = useTimeZoneClock(founder.time_zone!);
  const { isAdmin } = getUserTypeFromLocalStorage();

  const selectedOption = useOption(founder.selectedOption);

  const { startDate, endDate } = useProject(project);
  const aiSearchMutation = trpc.ai.searchFounder.useMutation();
  const aiStatus = founder.ai_status;
  const assignedDesigners = project.designers;

  const renderAISection = () => {
    switch (aiStatus) {
      case 'IDLE':
        return (
          <Button
            onClick={async () => {
              await aiSearchMutation.mutateAsync({ founderId: founder.id });
            }}>
            Start AI Search
          </Button>
        );
      case 'PROCESSING':
        return <Spinner />;
      case 'COMPLETED':
        return (
          <Markdown
            components={{
              a: ({ ...props }) => (
                <a
                  {...props}
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                />
              ),
            }}>
            {founder.ai_description}
          </Markdown>
        );
      case 'FAILED':
        return (
          <Button
            onClick={async () => {
              await aiSearchMutation.mutateAsync({ founderId: founder.id });
            }}>
            AI Search Failed, Retry
          </Button>
        );
    }
  };

  return (
    <div>
      <motion.section
        animate={{ opacity: 1, scale: 1 }}
        initial={{ opacity: 0, scale: 0.99 }}
        transition={{ duration: 0.2, ease: 'easeInOut' }}
        className="mx-auto flex flex-col items-center gap-20 pb-52">
        <div className="flex flex-col items-center">
          <FoundersTag
            founder={founder}
            renderSection={['stage', 'plan', 'services', 'duration']}
          />
          <CopyEmailButton
            className="mt-10"
            email={founder.user.email}
            text={founder.name!}
          />

          <span>{founder.company_name}</span>

          <span className="mt-2">
            {formatTimezone(founder.time_zone || '')}{' '}
            {dayjs(time).format('HH:mm')}
          </span>
        </div>
        {selectedOption && selectedOption.option && (
          <div>
            <h2>Deliverables</h2>
            <p className="mt-2">
              {sizeToMonth(selectedOption.maxDurationService.size)}
            </p>
            <p>{priceFormatter.format(selectedOption.price)}</p>

            <div className="mt-6 flex items-center justify-center gap-2">
              {selectedOption.allServices.map(service => {
                const counts = sizeToMountCount(service.size);
                const dots = [...Array(counts)].map((_, i) => (
                  <FeatureDot key={i} />
                ));
                return (
                  <FeatureContainer key={`${service.type}-${service.size}`}>
                    <FeatureGroup>
                      <span className="uppercase">
                        {service.type.charAt(0)}
                      </span>
                    </FeatureGroup>
                    <FeatureGroup className="gap-2">
                      {dots.length > 0 && dots}
                    </FeatureGroup>
                  </FeatureContainer>
                );
              })}
            </div>
          </div>
        )}

        {/* Assigned designers */}

        {isAdmin && (
          <div className="flex max-w-[900px] flex-wrap justify-center gap-4">
            {/*  <AssignUser designer={assignedDesigners[0]!} />
          <AssignUser designer={assignedDesigners[0]!} /> */}
            {assignedDesigners.map(designer => {
              return <AssignUser key={designer.id} designer={designer} />;
            })}
            <Button size={'lg'} asChild>
              <Link
                to="/dashboard/project/$projectId/assign"
                params={{ projectId: String(project.id) }}>
                Assign
              </Link>
            </Button>
          </div>
        )}
        <div>
          <h1>Project Timeline</h1>
          <p className="mt-2">
            {startDate.format('DD MMM dddd')} - {endDate.format('DD MMM dddd')}
          </p>
        </div>

        <div className="flex flex-col items-center gap-6">
          <h3>Desired outcome</h3>
          <p className="whitespace-pre-line text-center">
            {founder.project_description}
          </p>
        </div>

        {isAdmin && (
          <div className="flex flex-col items-center gap-6">
            <h3>AI Description ✨</h3>
            {renderAISection()}
          </div>
        )}
      </motion.section>
      <BottomNavigation left={<BackButton />} />
    </div>
  );
}
